.view_ticket_reason {
  width: 4rem;
  border-radius: 4px;
  border: none;
  height: 2rem;
}

.table_width1 {
    width: 1rem;
}

.table_width6{
    width: 6rem;
}