.file-container {
  border: 2px solid;
  border-color: #c5e3fc;
  border-radius: 7px;
  padding: 1rem;
}

.document-container {
  max-height: 250px;
  overflow-y: auto;
}

.upload_mini_loader_dept {
  width: 40px;
  height: 40px;
  border: 2px #347bde solid;
  border-radius: 50%;
  animation: rotation 1s ease-in-out infinite;
  margin: 30px auto;
}

.upload_mini_loader_dept:after {
  width: 8px;
  height: 8px;
  background-color: rgb(0, 0, 0);
  border-radius: 100%;
  position: absolute;
  content: "";
}