.year_award {
    overflow: auto;
    scroll-snap-type: x;
}

.awarded_empolyee_image_container {
    position: relative;
    height: 130px;
    width: 130px;
}

.awarded_empolyee_image {
    margin: auto;
    height: 120px;
    width: 120px;
    border-radius: 59px;
    border: 2px solid black;
    margin-bottom: 20px;
    overflow: hidden;
}

.yearly_award_content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.awarded_empolyee_image img {
    width: 100%;
    cursor: pointer;
    height: 100%;
}

.award_main_div {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 100px;
    margin-bottom: 20px;
}

.award_descriptonIcon {
    position: absolute;
    top: 5px;
    right: 0px;
    cursor: pointer;
}

.yearly_award_content h3 {
    text-wrap: nowrap;
    font-size: 18px;
}

.giventoname {
    font-size: 18px;
}