.salary-generation-button {
    /* margin-left: 10px; */
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.bg-red {
    background-color: #ff8080;
}

.salary-status-hold .ant-select-selection.ant-select-selection--single {
    background: #dc3545;
    color: #fff;
    height: 37px;
    border: none;
    border-radius: 7px;
}

.salary-status-clear .ant-select-selection.ant-select-selection--single {
    background: #65cca1;
    color: #fff;
    height: 37px;
    border: none;
    border-radius: 7px;
}
.otp_background {
    background-color: #696969;
    display: flex;
    align-items: center;
    justify-content: center;
}
