@import url("https://fonts.googleapis.com/css?family=Open+Sans");


.wrapper {
  background-color: white;
  border-radius: 0.3rem;
}

.email-input {
  width: 100%;
  height: 45px;
  padding: 0 1rem;
  box-sizing: border-box;

  font-family: circe, sans-serif;
  border-radius: 10px;
  border: 1px solid #d4d5d6;
  color: #565656;
  -webkit-appearance: none;
}

.email-input-height {
  height: 3.2rem !important;
}

.email-input:focus {
  border-color: #347bde;
  outline: none;
}

.email-input.input_error {
  border-color: red;
}


.tag-item {
  font-family: circe, sans-serif;
  color: white;
  background-color: #347bde;
  display: inline-block;
  font-size: 14px;
  border-radius: 30px;
  height: 30px;
  padding: 0 4px 0 1rem;
  display: inline-flex;
  align-items: center;
  margin: 0 0.3rem 0.3rem 0;
}


.tag-item>.button {
  background-color: white;
  color: black;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  font: inherit;
  margin-left: 10px;
  font-weight: bold;
  padding: 0;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.upload-modal {
  width: 30%;
}

.delete-compoff-btn{
  height: 2.4rem;
  margin-right: 1rem;
}

@media (max-width: 520px) {
  .delete-compoff-btn {
    font-size: 18px;
    margin-left: 1rem;
    margin-bottom: 2rem;
    margin-top: -2.5rem;
  }
}

.temp-compoff-details {
  font-size: 17px;
  line-height: 22px;
}