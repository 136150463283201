.organization-btn {
  background-color: #404e7c;
  color: #fef9ef;
  text-align: center;
  font-size: 1.5em;
  z-index: 1;
  border: none;
  width: 7rem;
  height: 3rem;
  margin-bottom: 1rem;
  text-decoration: none;
  border-radius: 8px;
}

.orgChart {
  height: calc(100vh - 60px);
  border: 1px solid #e4e2e9;
  border-radius: 5px;
}

.empDetailCard {
  position: absolute;
  top: 60px;
  width: 45%;
  padding: 2rem;
  margin: 2rem;
  background-color: #f2f2f2;
  border-radius: 1rem;
  border: 1px solid #d3d3d3;
  overflow-y: scroll;
}

.cardCloseBtn {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 30px;
  height: 30px;
  color: #227c9d;
  background-color: #fef9ef;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #227c9d;
  cursor: pointer;
}

.cardHeader {
  text-align: center;
  margin-bottom: 1rem;
}

.cardImg {
  width: 120px;
  border-radius: 1rem;
  height: 120px;
}
.cardName {
  margin-top: 1rem;
  font-size: 1rem;
  font-weight: bold;
}
.cardRole {
  margin: 1rem 0;
  font-size: 1.2rem;
}
.cardBody {
  display: flex;
  flex-direction: column;
}
.cardBodyTeamMembers {
  margin-top: 1rem;
  height: 26vh;
  overflow-y: auto;
}
.cardItem {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.cardItemLabel {
  margin: 0.5rem 0;
  font-size: 16px;
  font-weight: bold;
  line-height: 15px;
}
.cardItemValue {
  text-align: justify;
  font-size: 16px;
  font-weight: 500;
}
.cardItemTeam {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.cardItemImg {
  width: 50px;
  height: 50px;
  margin: 0.2rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cardItemText {
  font-size: 30px;
  color: white;
}

.cardItemName {
  margin-left: 0.5rem;
  font-weight: 500;
  font-size: 18px;
  line-height: 16px;
}
.cardItemTeamInfo {
  font-size: 0.8rem;
  margin-left: 0.5rem;
  font-weight: 100;
}

.icon {
  margin-right: 0.5rem;
}

.nodeContainer {
  width: 300px;
  height: 150px;
  padding-top: 25px;
  padding-left: 1px;
  padding-right: 1px;
}

.nodeDetails {
  font-family: "Inter, sans-serif";
  background-color: #fff;
  margin-left: -1px;
  width: 298px;
  height: 127px;
  border-radius: 10px;
  border: 1px solid #e4e2e9;
  box-shadow: 7px 7px #e4e2e9;
}
.nodeId {
  display: flex;
  justify-content: flex-end;
  margin-top: 5px;
  margin-right: 8px;
}

.nodeContent {
  background-color: #fff;
  margin-top: -47px;
  margin-left: 15px;
  border-radius: 100px;
  width: 50px;
  height: 50px;
}

.nodeImgContainer {
  margin-top: -47px;
}

.nodeNoProfile {
  margin-top: -50px;
}

.nodeImg {
  margin-left: 15px;
  border-radius: 100px;
  width: 50px;
  height: 50px;
  margin-top: -3px;
}

.nodeName {
  display: flex;
  font-size: 15px;
  color: #08011e;
  margin-left: 20px;
  margin-top: 10px;
}

.nodeDepartment {
  color: #716e7b;
  margin-left: 20px;
  margin-top: 3px;
  font-size: 10px;
}

.nodeInfoContainer {
  background-color: #f1f1f2;
  margin: 8px;
  line-height: 20px;
  border-radius: 6px;
}

.nodeInfo {
  gap: 4px;
}

.month_year {
  gap: 1rem;
}

.expandBtn {
  width: 30px;
  height: 30px;
  margin: auto;
  color: #227c9d;
  background-color: #fef9ef;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #d3d3d3;
  border-radius: 50%;
  cursor: pointer;
}

.backgroundOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.cardItemTeamDetails {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 82%;
}
