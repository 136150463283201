@media print {
  body * {
    visibility: hidden;
  }
  .template_detail * {
    visibility: visible;
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
  }
}

.calendar_container {
  padding: 1rem;
  background: #f1f1f1;
}

.calendar {
  width: 500px;
  border-radius: 8px;
  padding: 16px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background: white;
}

.calendar-header {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 16px;
  font-size: 1.2rem;
  font-weight: bold;
}

.calendar-grid {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 8px;
}

.calendar-day-name,
.calendar-day {
  text-align: center;
  padding: 8px;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 300;
}

.calendar-day-name {
  font-weight: bold;
}

.calendar-day {
  background-color: #f0f0f0;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.calendar-day.empty {
  background-color: transparent;
}

.halfLeave {
  background-color: #10efda;
}

.present {
  background-color: #92d3c3;
}

.leave {
  background-color: #f2a26b;
}

.absent {
  background-color: #f67a7a;
}

.holiday {
  background-color: #ccb3f6;
}

.no_attendance {
  background-color: #c2c0c0;
}

.calendar-day.prev-next-month {
  color: #c8c8ca;
  background-color: white;
}

.circle {
  display: inline-block;
  width: 10px;
  height: 10px;
  line-height: 30px;
  text-align: center;
  border-radius: 50%;
  color: #fff;
}

.template3_date {
  background: #14a445;
  width: fit-content;
  padding: 6px 14px;
  color: white;
  border-radius: 3px 3px 0px 0px;
  position: relative;
  left: 5rem;
  margin-top: 1rem;
}

.template3_data {
  border: 1px solid #14a445;
  min-height: 22rem;
  padding: 2rem 1rem 1rem 1rem;
  width: 100%;
  overflow: auto;
}

.template3_status {
  margin-left: 4px;
  font-size: 16px;
  font-weight: 300;
}

.template3_status_title {
  font-size: 16px;
  font-weight: 600;
}

.template3_table_width {
  min-width: 100px;
}

.template3_table_title_width {
  min-width: 130px;
}

.template3_table_body {
  border-left: 1px solid #c8c8ca;
  border-bottom: 1px solid #c8c8ca;
  border-right: 1px solid #c8c8ca;
  border-top: 2px solid white;
}

.template3_row {
  padding: 6px;
}

.template3_row_data {
  display: flex;
  justify-content: end;
  border: 1px solid;
  margin-left: 6px;
  padding-right: 3px;
}

.template3_col_header {
  background: #e3efef;
  border: 1px solid white;
}

.template3_week_header {
  border: 1px solid white;
}

.template3_total_hours {
  background: #fffede;
  padding-right: 3px;
}

.template3_container {
  padding-bottom: 3rem;
}

.final_total_hours {
  background-color: #ffcd3a;
}

.template3_tasks {
  font-size: 13px;
  font-weight: 400;
}
