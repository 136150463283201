/*  */
.investment-btn {
  font: 15px;
}
/*  */
.dob-font {
  font-weight: 100;
}
.text {
  text-decoration: underline;
}
h6 {
  font-size: 13px;
}
table tr td th thead {
  background-color: white !important;
}

.set-width {
  width: 5% !important;
}
.width {
  width: 25%;
}
.position {
  align-items: center;
  justify-content: space-between;
  padding-bottom: 50px;
}
hr {
  border: 1px solid grey;
}

.button-style {
  background-color: #117dedfa;
  padding: 9px;
  border-radius: 6px;
  color: white;
  font-weight: bold;
}

.text-size {
  font-size: 17px;
}
.input {
  border: none;
  width: 70%;
}
.input1 {
  width: 30%;
}
.error {
  color: red;
  float: right;
}
.input-box {
  border-bottom: 1px solid black;
}
.headings {
  display: flex;
}
.space {
  justify-content: space-between;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.table_name:hover {
  cursor: pointer;
}

@media print {
  body * {
    visibility: hidden;
  }
  .print-container,
  .print-container * {
    visibility: visible;
  }
  .print-container {
    position: absolute;
    left: 0;
    top: 0;
  }
}

.upload_investment_doc {
  position: relative !important;
  top: 0;
  right: 0 !important;
}

.search_icon {
  right: 12px;
  top: 14px;
}
  
.upload_container {
  border: 1px solid #b9bec1;
  border-radius: 10px;
  padding: 1rem;
  background: #e0f2f7;
}

.upload_view_btn {
  width: 60px;
}