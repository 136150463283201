.privacy_card {
  margin: 2rem;
  background-color: white;
  border-radius: 1rem;
  border: 1px solid #d3d3d3;
  width: 20rem;
  height: 15.5rem;
}

.privacy_img {
  width: 14rem;
  height: 14rem;
}

.privacy_ellipsis {
  display: flex;
  justify-content: end;
}

.privacy_card_container {
  gap: 4rem;
}

.privacy_img_container {
  border-radius: 1rem 1rem 0px 0px;
  height: 81%;
}

.privacy_img_container:hover {
  cursor: pointer;
}

.privacy_view_doc {
  width: 1rem;
  height: 1rem;
}

.privacy_view_doc_btn {
  position: absolute;
  right: 2px;
  bottom: 6px;
  border: none;
  background: #f2f2f2;
}

.policy_dropdown {
  top: 26px !important;
  right: 10px !important;
}

.privacy_card_title {
  display: flex;
  position: absolute;
  text-align: center;
  align-items: center;
  justify-content: center;
  bottom: -49px;
  background: rgb(169 183 230);
  font-family: Arial, sans-serif;
  width: 100%;
  min-height: 22%;
  border-radius: 0px 0px 1rem 1rem;
}

.canvaspdf {
  height: 700px;
}

.view_pdf {
  height: 500px;
  overflow: scroll;
  width: 100%;
}

.pdf_img {
  height: 200px;
}

.privacy_dropdown {
  position: absolute;
  right: 4px;
  top: 4px;
  z-index: 1;
}

.privacy_dropdown_menu .dropdown-menu {
  left: auto !important;
  right: 11px !important;
  transform: none !important;
  top: 40px !important;
  border: none;
  border-radius: 6px;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1) !important;
  z-index: 999;
  padding: 10px 0px;
  background: #eaeaea !important;
}

.privacy_dropdown_menu .dropdown-item {
  display: block;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

.dropdown-toggle {
  background: none;
  border: none;
}

.dropdown-toggle span {
  color: black;
}

.dropdown-toggle:hover,
.show > .btn-primary.dropdown-toggle {
  background-color: #eeeeee;
}

.policy_card_loader {
  top: 23%;
  position: absolute;
  right: 44%;
}

.dot-elastic {
  top: 50%;
  left: 50%;
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #9880ff;
  color: #9880ff;
  animation: dot-elastic 1s infinite linear;
}
.dot-elastic::before,
.dot-elastic::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
}
.dot-elastic::before {
  left: -15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #9880ff;
  color: #9880ff;
  animation: dot-elastic-before 1s infinite linear;
}
.dot-elastic::after {
  left: 15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #9880ff;
  color: #9880ff;
  animation: dot-elastic-after 1s infinite linear;
}

@keyframes dot-elastic-before {
  0% {
    transform: scale(1, 1);
  }
  25% {
    transform: scale(1, 1.5);
  }
  50% {
    transform: scale(1, 0.67);
  }
  75% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1, 1);
  }
}
@keyframes dot-elastic {
  0% {
    transform: scale(1, 1);
  }
  25% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1, 1.5);
  }
  75% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1, 1);
  }
}
@keyframes dot-elastic-after {
  0% {
    transform: scale(1, 1);
  }
  25% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1, 0.67);
  }
  75% {
    transform: scale(1, 1.5);
  }
  100% {
    transform: scale(1, 1);
  }
}
