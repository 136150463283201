.employeeDetails-container {
  width: 100%;
  padding-top: 6%;
}
.headersDiv {
  display: flex;
  justify-content:flex-start;
  flex-direction: row;
}
.contentDiv {
  margin-top: 1.4%;
}
.marginTop {
  margin-top: 2rem;
}
.employeeDetailsDiv {
  margin-left: 3rem;
}
.userInfo{
  font-family: 'Nunito', sans-serif;
  font-weight: 500;
}
@media screen and (max-width: 430px) {
  .headersDiv {
    flex-direction: column-reverse;
  }
  .employeeDetailsDiv {
    margin: 1rem 0rem 1rem 0rem;
  }
}
@media screen and (max-width: 1330px) {
  .employeeDetails-container {
    padding-top: 1%;
  }
}
.actionDiv{
  width:100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.tableDescDiv{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}
/* ----------modal css ------------- */

.modalButtonsDiv{
  display: flex;
  justify-content: flex-end;
}
.loaderDiv{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Nunito', sans-serif;
}

.display{
  justify-content: flex-end!important;
}
.table_name{
  color:blue;
}