.select_year_cal.rang_pick {
  width: 234px;
  height: 50px !important;
  border: 1px solid #c4c9ce;
}

span.rang.select_year_cal.rang_pick.ant-calendar-picker {
  margin-top: 0 !important;
}

span.rang.select_year_cal.rang_pick.ant-calendar-picker:focus {
  box-shadow: none !important;
}

.ant-calendar-picker-input.ant-input {
  margin-top: 2px;
}
.ant-table {
  overflow-x: scroll;
}
.ant-table-thead > tr > th{
background-color: #347bde;
color: white;
font-weight: 500;
}