.teamchart_main_div {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}

.attendance_team_chart {
    border-right: solid black;
}

.leave_team_chart {
    border-left: solid black;
}


.chartjs-render-monitor {
    width: 800px;
    height: 500px;
    max-width: 100%;
}

.team_search_bar {
    padding-left: 183px;
}

.team_search_bar .form-control {
    height: 54px;
    padding-right: 30px;
}

span.team_clear {
    position: absolute;
    width: 12px;
    right: 30px;
    top: 18px;
    cursor: pointer;
}

@media screen and (max-width: 820px) {
    .teamchart_main_div {
        grid-template-columns: 1fr;
    }

    .team_search_bar {
        padding: 0px;
    }


    .attendance_team_chart {
        border-bottom: solid black;
        border-right: none;
        margin-bottom: 5px;
        padding-bottom: 30px;
    }

    .leave_team_chart {
        border-left: none;
    }

    .project_team_chart {
        border-bottom: solid black;
        border-right: none;
        margin-bottom: 5px;
        padding-bottom: 30px;
    }
}

.highcharts-credits {
    display: none;
}
.nested_table{
    display: flex;
    padding: 0px;
}

.nested_table td{
    width: 50%;
    text-align: center;
}
@media screen and (max-width: 768px) {
    .team_add_button {
        margin-top: 5px;
    }
}