.calendar_container2 {
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
}

.calendar2 {
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  overflow: hidden;
}

.calendar-header2 {
  text-align: center;
  padding: 16px;
  background-color: #f5f5f5;
  border-bottom: 1px solid #e0e0e0;
}

.calendar-grid2 {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
}

.calendar-day-name2 {
  text-align: center;
  padding: 8px;
  background-color: #f5f5f5;
  border: 1px solid #eeeeee;
  font-size: larger;
  font-weight: 500;
}

.calendar_day {
  height: 70px;
  align-items: start;
  justify-content: space-around;
}

.prev_next_month {
  background-color: #f2f6f7;
}

.time-slots {
  font-size: 12px;
  margin-top: 4px;
}

.status_Absent,
.status_Present,
.status_Leave,
.status_HalfDay,
.status_Holiday {
  font-size: 14px;
  font-weight: bold;
  margin-top: 4px;
}

.status_A {
  background-color: #f67a7a;
}

.status_P {
  background-color: #92d3c3;
}

.status_L {
  background-color: #f2a26b;
}

.status_H-D {
  background-color: #10efda;
}

.status_H {
  background-color: #ccb3f6;
}

.color-description {
  padding: 16px;
  background-color: #f5f5f5;
}

.template2_status_detail {
  font-size: 12px;
  font-weight: 400;
  padding: 2px 4px;
}

.privacy-card-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.days {
  top: 3px;
  right: 8px;
}

.emp_time {
  font-size: 12px;
  font-weight: 300;
  /* position: absolute; */
  left: 12px;
  top: 5px;
  color: #a69b9b;
  margin-left: 8px;
}

.emp_status {
  /* position: absolute; */
  /* bottom: 12%; */
  font-size: 12px;
  font-weight: 400;
  /* left: 11px; */
  padding: 2px 4px;
}

.time_day {
  width: 100%;
  padding: 0px 5px;
}

.statusClass {
    width: 100%;
    margin-left: 26px;
}
