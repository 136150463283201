@font-face {
    font-family: 'TrashHand';
    src: url('../emailTemplate/cardfonts/TrashHand.eot');
    src: url('../emailTemplate/cardfonts/TrashHand.eot') format('embedded-opentype'),
        url('../emailTemplate/cardfonts/TrashHand.woff2') format('woff2'),
        url('../emailTemplate/cardfonts/TrashHand.woff') format('woff'),
        url('../emailTemplate/cardfonts/TrashHand.ttf') format('truetype'),
        url('../emailTemplate/cardfonts/TrashHand.svg') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
}

:root {
    --color-text-brown: #512D17;
    --color-text-white: #fff;
}


body {
    box-sizing: border-box;
}

.card-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.card-box {
    max-width: 600px;
    position: relative;
}

.card-bg {
    width: 100%;
    height: 100%;
}

.person-img-box {
    position: relative;
    text-align: center;

}

.person-img {
    transform: translateY(7.5%);
    width: 100%;
    clip-path: circle(50% at 50% 50%);
}

.center-content {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: grid;
    grid-template-columns: 1fr 5.12fr 1fr;
}

.ribbon-box {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 10;
}

.ribbon {
    width: 100%;
    height: 100%;
}

.round-shadow {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    transform: translateY(7.5%);
}

.cake {
    position: absolute;
    top: 67%;
    left: 17%;
    width: 80.2%;
    /* transform: translateY(-100px) translateX(50px); */
}

.person-name-box {
    width: 100%;
    text-align: center;
    font-size: 75px;
    position: absolute;
    top: 119%;
    left: 0;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    line-height: 85px;
    font-family: 'TrashHand';
    color: var(--color-text-brown);
}



@media only screen and (max-width: 500px) {
    .person-name-box {
        font-size: 60px;
        top: 116%;
    }
}

@media only screen and (max-width: 440px) {
    .person-name-box {
        font-size: 45px;
        top: 115%;
    }
}

@media only screen and (max-width: 370px) {
    .person-name-box {
        font-size: 45px;
        top: 113%;
    }
}


.template-container {
    /* display: grid; */
    grid-template-columns: 1fr 1fr;
    column-gap: 20px;
}

.birthday-dropdown {
    align-self: center;
}

.birthday-dropdown__btn {
    border: none;
    font-size: 14px;
    background-color: #347bde;
    color: #fff;
    padding: 5px 10px;
    border-radius: 5px;
    font-weight: 500;
    /* border: 3px solid #2962b3; */

}


.back_btn {
    position: sticky;
    top: 100px;
    align-self: flex-start;
}
.person-name-box1 .person-name{
    font-size: 48px;
    line-height: 60px;
    font-weight: 800;
    font-style: italic;
    margin-top: 30px;
    text-transform: capitalize;
    display: block;
}
.person-name-box2 .person-name{
    font-size: 13px;
    line-height: 20px;
        font-weight: normal;
    color: #000;
    display: block;
    margin: 10px auto;
}
.error{
    color: red;
}
.person-name-box1 .person-name2{
    font-size: 40px;
    line-height: 50px;
    margin: 16px 0px 0px;    
}
.person-name-desc{
    font-size: 13px;
    line-height: 18px;
    font-weight: 400;
}
.person-name-desg{
    font-size: 18px;
    line-height: 30px;
}
.person-name-box11 .person-name{
    font-size: 35px;
    line-height: 40px;
    font-weight: 800;
    font-style: italic;
    margin-top: 30px;
    text-transform: capitalize;
    display: block;
}

