.opt_contanier {
    border-radius: 15px;
    background-color: white;
    padding: 20px;
}

.opt_contanier h2 {
    color: #8f9294;
    font-weight: 600;
}

.get_otp_container {
    width: 500px;
    height: 150px;
    justify-content: center;
}


.get_otp_input_container {
    width: 80px !important;
    height: 80px;
    font-size: 30px;
    color: #8f9294;

    border: none;
    border-bottom: 2px solid #c4c9ce;
    margin-right: 20px;
}

@media screen and (max-width: 720px) {
    .get_otp_container {
        width: 400px;
        height: 150px;
        justify-content: center;
    }
    .get_otp_input_container {
        width: 50px !important;
        height: 50px;
        font-size: 20px;
        border: none;
        border-bottom: 2px solid #c4c9ce;
        margin-right:15px;
    }

}
@media screen and (max-width: 460px) {
    .get_otp_container {
        width: 300px;
        height: 150px;
        justify-content: center;
    }
    .get_otp_input_container {
        width: 40px !important;
        height: 40px;
        font-size: 10px;
        /* border-radius: 10px; */
        border: none;
        border-bottom: 2px solid #c4c9ce;
        margin-right:15px;
    }

}

.get_otp_input_container:focus {
    outline: none;
}

.get_otp {
    text-align: end;
    color: blue;
    max-width: 700px;
    cursor: pointer;
    font-size: 20px;
}

.validate_button {
    padding: 15px 80px 15px 80px;
    font-size: 30px;
    border-radius: 15px;
}