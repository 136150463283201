@import url(https://fonts.googleapis.com/css?family=Open+Sans);
body {
  color: #000;
  font-family: circe, sans-serif;
}

.form-control {
  display: block;
  width: 100%;
}

.ant-time-picker-input::placeholder,
.ant-calendar-range-picker-input::placeholder,
.ant-time-picker-input::placeholder {
  color: #000000ad;
  opacity: 1;
  /* Firefox */
}

.ant-time-picker-input:-ms-input-placeholder,
.ant-time-picker-input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #000000ad;
}

.selectHeight {
  height: auto !important;
}

.ant-time-picker-input::-ms-input-placeholder,
.ant-time-picker-input::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #000000ad;
}

.ant-calendar-range {
  width: 100%;
}

.ant-calendar-input::placeholder,
.ant-calendar-picker-input::placeholder {
  color: #000000ad;
  opacity: 1;
  /* Firefox */
}

.ant-calendar-picker-input:-ms-input-placeholder,
.ant-calendar-input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #000000ad;
}

.ant-calendar-picker-input::-ms-input-placeholder,
.ant-calendar-input::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #000000ad;
}

.cm_loader {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: #fff;
  z-index: 99999;
}

.s_cm_loader {
  position: absolute;
  width: 100%;
  /* height: 100%; */
  left: 0;
  /* top: 0; */
  /* background: #fff; */
  z-index: 99999;
}


.cm_loader svg {
  position: absolute;
  width: 150px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.s_cm_loader svg {
  position: absolute;
  width: 150px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.select_emp_leave.select_type.ant-select.ant-select-enabled {
  width: 250px;
}

.login_wrapper {
  background: #347bde;
  height: 100vh;
  min-height: 630px;
}

.bg_white {
  background: #fff;
  border-radius: 20px;
  padding: 100px;
  min-height: 729px;
}

img.login {
  max-width: 100%;
}

.form-login {
  border: 1px solid #e9e9eb;
  padding: 50px 30px;
  border-radius: 8px;
  max-width: 75%;
  margin: 0 auto;
  margin-top: 30px;
}

.fp_form .form-control {
  border-radius: 160px;
  padding-left: 45px;
  font-weight: 600;
  min-height: 48px;
}

.form-login .form-control {
  border-radius: 160px;
  padding-left: 45px;
  font-weight: 600;
  min-height: 48px;
}

.mini_loader_dept.edu_loader {
  left: 224px;
  bottom: -14px;
}

.cm_icon {
  position: absolute;
  width: 17px;
  left: 16px;
  top: 12px;
}

input.ant-calendar-picker-input.ant-input {
  width: 80%;
  left: 2px;
  font-size: 15px;
  top: 2px;
}

.form-group {
  position: relative;
  margin-bottom: 1.5rem;
}

.fp_link {
  color: #686868;
  font-weight: bold;
  display: block;
  margin-top: 30px;
  font-size: 20px;
}

.cm_heading {
  font-size: 16px;
  font-weight: bold;
  text-align: center;
}

.btn-primary {
  cursor: pointer;
  background: #347bde;
}

.btn-primary:disabled {
  cursor: not-allowed;
  background: #347bde;
}

.activity-pointer {
  cursor: pointer;
}

.page_content {
  margin: 0 auto;
  max-width: 511px;
}

.fp_form {
  max-width: 71%;
  margin: 0 auto;
}

.login_wrapper p {
  color: #7f7f7f;
}

.cm_btn {
  border-radius: 30px;
  border-color: #347bde;
  padding: 15px 63px;
  line-height: 1;
  font-size: 16px;
  color: #347bde;
  display: block;
  width: 100%;
}

.cm_check {
  width: 50px;
  height: 50px;
  background: #347bde;
  border-radius: 100%;
  margin: 0 auto;
  margin-bottom: 30px;
  position: relative;
}

.cm_check:before {
  content: "";
  width: 16px;
  height: 25px;
  border-right: 3px solid #fff;
  border-bottom: 3px solid #fff;
  position: absolute;
  top: 9px;
  transform: rotate(45deg);
  left: 16px;
}

.menu_icon {
  position: absolute;
  width: 40px;
  height: 40px;
  padding: 7px;
  border-radius: 7px;
  line-height: 25px;
  left: 29px;
  top: 7px;
}

a.active .menu_icon {
  background: #1d467f;
}

.collapse ul li,
.collapse ul li a.active {
  background-color: blue;
}

.collapse ul li :hover,
.collapse ul li a.active .menu_icon {
  background-color: rgb(57, 57, 154);
}

a.active {
  background: #2d6bce;
}

.sidebar ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.sidebar .accordian ul li {
  margin: 0;
  padding: 0;
  list-style: none;
}

.sidebar ul li {
  position: relative;
  padding: 10px;
  padding-left: 100px;
}

.toogle_btn {
  padding: 0px;
  width: 100%;
}

.sidebar .accordian ul li {
  position: relative;
  padding: 10px;
  padding-left: 100px;
}

.sidebar {
  position: fixed;
  background: #347bde;
  height: 100%;
  width: 330px;
  left: 0;
  top: 0;
  max-height: 100vh;
  overflow-y: auto;
}

.sidebar ul li a {
  color: #fff;
  display: block;
  padding: 16px;
  padding-left: 100px;
}

.expandable {
  color: #fff;
  display: block;
  padding: 16px;
  padding-left: 100px;
  font-size: 14px;
  text-align: left;
}

.expandable span svg {
  filter: invert(1);
}

.expandable span svg g {
  filter: invert(1);
}

.safari span svg {
  filter: invert(0) !important;
}

.safari span svg g {
  filter: invert(0) !important;
}

.cm_status_color span {
  position: absolute;
  margin-left: 9px;
  font-size: 23px;
  font-weight: bold;
}

.cm_img_loader {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;
  color: #fff;
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  border-radius: 11px;
  text-align: center;
}

.fa.fa-trash-o.disable {
  cursor: not-allowed;
  color: rgba(0, 0, 0, 0.45);
}

.nav-tabs .nav-link:hover {
  color: #000 !important;
  background-color: #2d6bce !important;
  border-color: #2d6bce !important;
}

.ant-calendar-picker-icon {
  display: inline-block;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.65);
}

.cm_exp_select .ant-select-selection__rendered {
  position: relative;
  display: block;
  margin-right: 11px;
  margin-left: 5px;
  line-height: 30px;
}

.search_device_bar2 {
  padding-right: 166px;
  padding-left: 195px;
}

.search_bar_btn2 {
  right: 166px;
  font-weight: bold;
  background: #2d6bce;
  color: #fff;
  position: absolute;
  border-radius: 7px;
  top: 0;
  width: 121px;
  height: 42px;
}

.search_emp span.cm_clear3 {
  right: 30px;
}

.search_emp span.cm_clear4 {
  right: 30px;
}

.sidebar ul li a svg circle,
.sidebar ul li a svg path {
  fill: #fff;
}

.sidebar ul li {
  position: relative;
  padding-left: 0;
  border-bottom: 1px solid #ffffff4f;
  padding: 0 !important;
}

.logo-white {
  display: block;
  width: auto;
  margin: 65px auto;
}

header {
  position: absolute;
  z-index: 99 !important;
  right: 0;
  width: auto;
  padding: 15px 40px;
  text-align: right;
  top: 0;
}

.list_black .ant-select-selection.ant-select-selection--single {
  border: none !important;
}

.cm_select_year .ant-select-selection.ant-select-selection--single {
  border: none !important;
}

.ant-select-selection:focus,
.ant-select-selection:active {
  color: #495057 !important;
  background-color: #fff !important;
  border-color: #80bdff !important;
  outline: 0 !important;
  border-radius: 10px;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25) !important;
}

.ant-select-selection:hover {
  color: #495057 !important;
  background-color: #fff !important;
  border-color: #80bdff !important;
  outline: 0 !important;
  border-radius: 10px;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25) !important;
}

.search_device_bar1 {
  padding-right: 194px;
  padding-left: 183px;
}

.search_device_bar3 {
  padding-right: 168px;
  padding-left: 208px;
}

.search_device_bar4 {
  padding-right: 168px;
  /* padding-left: 208px; */
  margin-bottom: 60px;
}

.search_bar_btn1 {
  right: 189px;
  font-weight: bold;
  background: #2d6bce;
  color: #fff;
  position: absolute;
  border-radius: 7px;
  top: 0;
  width: 121px;
  height: 42px;
}

.profile_user,
.profile_user:not(:disabled):not(.disabled):active,
.profile_user:hover {
  background: none !important;
  border: none !important;
  font-weight: bold !important;
  color: #000 !important;
  box-shadow: none !important;
  position: relative;
  padding: 0;
}

.dropdown-toggle::after {
  display: none !important;
}

.cm_drop {
  position: absolute;
  right: 37px;
  top: 10px;
}

.fa.fa-sort-numeric-asc.ml-2 {
  cursor: pointer;
}

.fa.fa-sort-numeric-desc.ml-2 {
  cursor: pointer;
}

.cm_drop:before {
  content: "";
  width: 12px;
  height: 12px;
  border-right: 2px solid #000;
  border-bottom: 2px solid #000;
  position: absolute;
  transform: rotate(45deg);
  right: 37px;
}

i.fa.fa-camera.pic-upload {
  font-size: 26px;
}

i.fa.fa-envelope-o.blue.cm_bold.send_mail {
  font-size: 18px;
  font-weight: bold;
}

.cm_breadcrumb_arrow:before {
  top: 4px;
  content: "";
  width: 12px;
  height: 12px;
  border-right: 2px solid #000;
  border-bottom: 2px solid #000;
  position: absolute;
  transform: rotate(-45deg);
  right: -24px;
}

.ant-modal-body {
  padding: 24px 0;
  font-size: 14px;
  line-height: 1.5;
  word-wrap: break-word;
}

.cm_breadcrumb li {
  display: inline-block;
  position: relative;
  margin-right: 33px;
}

.user_img {
  border-radius: 100%;
  height: 45px;
  width: 45px;
  margin-left: 0;
  object-fit: cover;
  margin-right: 24px;
}

img.user_img1 {
  width: 48px;
  height: 48px;
  border-radius: 100%;
  object-fit: cover;
}

.cm_user_img img {
  width: 242px;
  height: 282px;
  object-fit: cover;
  border-radius: 20px;
  margin-bottom: 60px;
}

.body_container {
  margin-left: 330px;
  padding-top: 74px;
  padding: 0 45px;
  position: relative;
  min-height: 100vh;
  padding-top: 74px;
  padding-bottom: 1px !important;
}

.pagination {
  margin-bottom: 30px;
}

.selectHeight .ant-select-selection--multiple {
  height: auto !important;
  min-height: 50px;

  border-radius: 10px;
}

li.ant-select-selection__choice {
  top: -10px;
}

.selectDesig .ant-select-selection__placeholder,
.ant-select-search__field__placeholder {
  font-size: 17px;

  color: rgba(0, 0, 0, 0.59) !important;
}

.cm_card {
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);
  transition: 0.3s;
  background: #fff;
  border-radius: 11px;
  padding: 25px;
  position: relative;
  margin-bottom: 25px;
}

.table {
  width: 100%;
  margin-bottom: 0 !important;
}

.table-responsive {
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);
  background: #fff;
  border-radius: 11px;
  margin-bottom: 30px;
  border-collapse: collapse;
  overflow: hidden;
}

.table_loader {
  position: relative;
}

.table tr:last-child td {
  border-bottom: none;
}

tr td:first-child {
  border-bottom: none;
}

tr td:first-child {
  border-left: none;
}

tr td:last-child {
  border-right: none;
}

.rej_btn {
  width: 69px;
}

ul.proj_member li:last-child {
  border-right: none;
}

ul.proj_participant li:last-child {
  border-right: none;
}

/* ul.cm_col_dept {
    list-style-type: none;
    columns: 2 !;
    -webkit-columns: 2 !important;
    -moz-columns: 2 !important;
}
li:before {
  content: "• ";
} */

.cm_col_dept::after {
  content: "  ,  ";
}

.cm_col_dept:last-child::after {
  content: "";
}

.dept_show {
  display: flex;
}

span.add_btn.add-skill.mb-2 {
  right: -26px;
}

.table-responsive {
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);
  background: #fff;
  border-radius: 11px;
  margin-bottom: 30px;
  border-collapse: collapse;
  overflow: auto !important;
  scroll-snap-type: x;
}

.mini_loader_device {
  width: 40px;
  height: 40px;
  border: 2px #347bde solid;
  border-radius: 50%;
  animation: rotation 1s ease-in-out infinite;
  margin: 30px auto;
  position: absolute;
  left: 188px;
  bottom: -26px;
}

.mini_loader_device:after {
  width: 8px;
  height: 8px;
  background-color: rgb(0, 0, 0);
  border-radius: 100%;
  position: absolute;
  content: "";
}

span.cm_col_desig {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  max-width: 275px;
}

.cm_col {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  max-width: 170px;
}

.cm_col_dots {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  max-width: 95px;
}

.cm_atten_date.ant-select.ant-select-enabled {
  position: absolute;
  right: 91px;
  border: none;
  background: #fff;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);
}

h3 {
  font-weight: bold;
  font-size: 23px;
}

.cm_card p {
  font-size: 18px;
  margin-bottom: 0px;
}

.cm_card h2 {
  font-size: 3rem;
  font-weight: bold;
}

.skyblue {
  color: #4ec6da;
}

.purple {
  color: #7460ee;
}

.blue {
  color: #2d6bce;
}

.yellow {
  color: #f6b12b;
}

.cm_status_color label {
  font-size: 18px;
  color: rgba(0, 0, 0, 0.58);
}

.comp_btn {
  color: #fff;
  /* width: 200px; */
  font-weight: bold;
  font-size: 16px;
  border: none;
  border-radius: 9px;
  height: 39px;
  background: #7460ee;
}

.leaves-provided {
  font-size: 16px;
}

.deduct-button {
  color: #fff;
  font-weight: bold;
  font-size: 16px;
  border: none;
  border-radius: 9px;
  height: 39px;
  background: #347bde;
  margin-left: 4px;
}

.device_history_images .modal-dialog {
  min-width: 90%;
  min-height: 90%;
}

.cm_list.cm_list_mb.list_black.ant-select.ant-select-enabled {
  bottom: -34px;
}

.row.leave_cm_card.card_mt {
  margin-top: 73px;
}

.cm_status_color span {
  position: absolute;
  margin-left: -13px;
  font-size: 23px;
  font-weight: bold;
}

.red {
  color: #ef534f;
}

.green {
  color: #65cca1;
}

.white {
  color: #fff;
}

.darkblue {
  color: #214cb0;
}

.skyblue {
  color: skyblue;
}

.brown {
  color: brown;
}

.lime {
  color: lime;
}

.orange {
  color: orange;
}

.grey {
  color: grey;
}

.black {
  color: black;
}

.pink {
  color: pink;
}

.redOrange {
  color: orangered;
}

.search_atten_bar {
  padding-right: 273px;
  padding-left: 148px;
}

span.atten_clear {
  position: absolute;
  width: 12px;
  right: 290px;
  top: 11px;
  cursor: pointer;
}

.add_atten {
  background: #2d6bce;
  position: absolute;
  top: 1px;
  text-align: center;
  border-radius: 6px;
  height: 40px;
  right: 127px;
}

button.btn.btn-primary.btn_csv {
  background: #2d6bce;
  z-index: 99;
  position: absolute;
  right: 50px;
  top: 145px;
}

input.form-control.mr-lg-2.atten_box.cm_box {
  height: 43px;
}

.atten_clear1 {
  top: 215px;
  z-index: 9999;
  font-size: 19px;
  cursor: pointer;
  right: 68px;
  position: absolute;
}

i.fa.fa-times-circle {
  font-size: 19px;
}

button.send_wish {
  border-radius: 8px;
  border: 0;
  background: #7460ee;
}

i.fa.fa-envelope-o {
  font-size: 19px;
  font-weight: bold;
}

button.send_wish {
  box-shadow: none !important;
}

button.send_wish:hover {
  background: #7460ee !important;
}

.card_num {
  position: absolute;
  right: 25px;
  top: 50%;
  font-size: 2.5rem;
  font-weight: bold;
  transform: translateY(-50%);
}

.card_time {
  position: absolute;
  right: 25px;
  top: 50%;
  font-size: 3rem;
  font-weight: bold;
  transform: translateY(-50%);
}

.cm_card.cm_pr {
  padding-right: 60px;
}

.leave_cm_card .cm_card.cm_pr {
  padding-right: 60px;
  height: 121px;
}

.card1 {
  border-bottom: 16px solid skyblue;
}

.card2 {
  border-bottom: 16px solid blue;
}

.card3 {
  border-bottom: 16px solid red;
}

.card4 {
  border-bottom: 16px solid orange;
}

.card5 {
  border-bottom: 16px solid yellow;
}

.card6 {
  border-bottom: 16px solid black;
}

.card7 {
  border-bottom: 16px solid grey;
}

.card8 {
  border-bottom: 16px solid pink;
}

.card9 {
  border-bottom: 16px solid green;
}

.card10 {
  border-bottom: 16px solid lime;
}

.card11 {
  border-bottom: 16px solid brown;
}

.card12 {
  border-bottom: 16px solid orangered;
}

.card13 {
  border-bottom: 16px solid red;
}

.card14 {
  border-bottom: 16px solid black;
}

.card15 {
  border-bottom: 16px solid red;
}

.card16 {
  border-bottom: 16px solid #9880ff;
}
.card17 {
  border-bottom: 16px solid rgb(25 177 83);
}
.card18 {
  border-bottom: 16px solid rgba(152, 12, 227, 0.245);
}

.purple {
  color: #9880ff;
}
.green {
  color: rgb(25 177 83);
}
.violet {
  color: rgba(152, 12, 227, 0.245);
}
/* .cm_card.card4.card_padd {
  padding-bottom: 0px;
  padding-top: 13px;
} */
.cm_bold {
  font-weight: bold;
}

.users_img {
  border-radius: 100%;
  height: 32px;
  width: 32px;
  object-fit: cover;
  margin-right: 5px;
}

.tab_btn {
  display: block;
  width: 100%;
  background: #fff;
  color: #000;
  padding: 15px;
  border: none;
  border-radius: 8px;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);
}

.tab_btn:hover,
.tab_btn.active {
  border: none;
}

.btn_sblue:hover,
.btn_sblue.active {
  background: #4ec6da !important;
}

.btn_blue:hover,
.btn_blue.active {
  background: #2d6bce !important;
}

.btn_yellow:hover,
.btn_yellow.active {
  background: #f6b12b !important;
}

.btn_purple:hover,
.btn_purple.active {
  background: #7460ee !important;
}

p.status {
  font-size: 27px;
}

li.row > div {
  padding: 20px 35px;
}

.card_num1 {
  color: #000;
  font-size: 2.5rem;
  font-weight: bold;
  display: inline-block;
  vertical-align: middle;
  margin-left: 45px;
}

.project ul li:first-child {
  border-bottom: 1px solid #4ec6da3d;
  margin-left: -65px;
  margin-right: -25px !important;
  padding-left: 50px;
  padding-right: 15px;
  font-size: 24px;
}

.project ul li:first-child > div {
  padding: 0px 30px;
  padding-bottom: 5px;
}

.cm_sel {
  position: absolute;
  right: 16px;
  top: 15px;
}

label.cm_rememberMe {
  position: absolute;
  top: 14px;
  color: rgba(0, 0, 0, 0.65);
  left: 24px;
  font-size: 14px;
  font-weight: bold;
}

img.image_loader {
  width: 130px !important;
  height: 130px !important;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

img.image_skill {
  width: 34px !important;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.cm_sel:before {
  content: "";
  width: 12px;
  height: 12px;
  border-right: 2px solid #000;
  border-bottom: 2px solid #000;
  position: absolute;
  transform: rotate(45deg);
}

.cm_no {
  font-size: 3rem;
}

.sm_card .cm_card {
  min-height: 220px !important;
}

.bg_skyblue {
  background: #4ec6da;
}

.bg_purple {
  background: #7460ee;
}

.bg_blue {
  background: #347bde;
}

.bg_yellow {
  background: #f6b12b;
}

.bg_red {
  background: #ef534f;
}

.title {
  margin-bottom: 50px;
  margin-top: 35px;
}

.cm_input {
  min-height: 156px;
  border-radius: 11px;
}

.cm_leaves {
  border-radius: 11px;
}

.eye_icon1 {
  position: absolute;
  width: 22px;
  top: 33px;
  right: 33px;
  cursor: pointer;
}

.ant-calendar-picker {
  display: block;
}

.ant-calendar-picker-input {
  height: 46px;
  font-size: 16px;
  transition: 0.3s;
  background: #fff;
  border-radius: 11px;
  border: none;
}

select#year {
  height: 49px;
  font-size: 16px;
  transition: 0.3s;
  background: #fff;
  border-radius: 11px;
  border: none;
}

.cm_box {
  height: 50px;
  font-size: 16px;
  background: #fff;
  border-radius: 11px;
  border: 1px solid #c4c9ce;
}

.leave_tab_button {
  left: 25rem;
  border-color: #347bde !important;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);
  color: #2d6bce !important;
  background: #fff !important;
  margin-bottom: -40px !important;
  margin-right: 9px !important;
  margin-left: 345px !important;
  border-radius: 8px !important;
  width: 8rem;
  height: 39px;
  text-align: center;
  top: 0px;
  margin-top: -4.3rem;
}

.leave_tab_button .ant-select-selection {
  margin-top: 0;
}

.leave_tab_button .ant-select-selection--single {
  position: relative;
  min-height: 2.4rem;
  border-radius: 8px;
  border-color: #2d6bcd;
  cursor: pointer;
  border: 1px solid;
}

.leave_tab_button .ant-select-selection-selected-value {
  left: 3px;
  color: #2d6bce;
  top: -6px;
  font-size: 15px;
  font-weight: bold;
  position: absolute;
  opacity: 1;
  display: contents;
}

.leave_tab_button .ant-select-arrow svg {
  display: inline-block;
  color: #2d6bce;
  margin-left: auto;
}

.edit-leave-input {
  max-width: 100%;
}

.leave_tab_button .ant-select-selection__placeholder {
  font-size: 14px;
  color: rgb(45, 107, 206) !important;
  left: 37px;
  top: 8px;
  font-weight: bold;
}

.leave_tab_button1 .ant-select-selection--single {
  position: relative;
  height: 18px;
  cursor: pointer;
}

.leave_tab_button1 {
  /* left: -274px; */
  /* border-color: #347bde!important; */
  /* box-shadow: 0 2px 20px 0 rgba(0,0,0,0.1); */
  /* color: #2d6bce !important; */
  /* background: #fff !important; */
  /* margin-bottom: -60px !important; */
  /* margin-right: 9px !important; */
  /* border-radius: 8px !important; */
  width: 134px;
  height: 39px;
  /* text-align: center; */
  /* top: 21px; */
}

.leave_tab_button1 .ant-select-selection-selected-value {
  left: 3px;
  /* color: #2d6bce; */
  top: -6px;
  font-size: 14px;
  font-weight: bold;
  position: absolute;
  opacity: 1;
}

.leave_tab_button1 .ant-select-selection__placeholder {
  font-size: 14px;
  /* color: rgb(45, 107, 206) !important; */
  left: 11px;
  top: 10px;
  font-weight: bold;
}

.leave_tab_button1 .ant-select-arrow svg {
  display: inline-block;
  margin-left: auto;
  /* color: #2d6bce; */
}

.modal {
  top: 45px;
}

.modal-content {
  padding-bottom: 2%;
  border-radius: 11px;
  border: none;
  padding-top: 7%;
}

.cm_deviceInfo .modal-content {
  padding-bottom: 0 !important;
  border-radius: 11px;
  border: none;
  padding-top: 60px;
}

.cm_deviceInfo .modal-header {
  padding: 0rem 1rem !important;
}

.full-image .modal-content {
  background: transparent;
  max-width: 100vw;
  height: 950px;
}

.full-image .modal-content img {
  max-width: 100%;
  /* height: auto; */
}

.full-image .modal-content .modal-body {
  display: flex;
  justify-content: center;
  align-items: center;
}

.full-image .modal-dialog {
  max-width: 95%;
  max-height: 100%;
}

.partial_approve .modal-dialog {
  max-width: 60%;
}

.yellow_btn {
  color: #fff;
  width: 126px;
  font-weight: bold;
  font-size: 16px;
  border: none;
  border-radius: 9px;
  height: 39px;
  margin-left: 32px;
  background: #f6b12b;
}

button.btn.btn-sm.btn-carry {
  color: #fff;
  font-weight: bold;
  font-size: 13px;
  border: none;
  border-radius: 9px;
  background: #f6b12b;
}

button.btn.btn-sm.btn-paid {
  color: #fff;
  font-weight: bold;
  font-size: 14px;
  border: none;
  border-radius: 9px;
  background: #4ec6da;
}

span.cm_col_desig {
  display: inline-block;

  overflow: hidden !important;
  text-overflow: ellipsis;

  word-break: break-all;
}

.blue_btn {
  color: #fff;
  width: 126px;
  font-weight: bold;
  font-size: 16px;
  border: none;
  border-radius: 9px;
  height: 39px;
  background: #4ec6da;
}

.ant-modal-content {
  position: relative;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-radius: 11px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  pointer-events: auto;
}

.modal-header {
  border: none;
}

.cm-des {
  min-height: 100px;
}

.cm_select {
  text-align: right;
}

.cm_select .dropdown-toggle {
  width: 160px;
  color: #000;
  border: 0;
  background: #fff;
  text-align: left;
}

.cm_select .dropdown-menu {
  top: 5px !important;
  border: 0;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);
  background: #fff;
  border-radius: 11px;
  padding: 15px;
}

.cm_select .dropdown-toggle:not(:disabled):not(.disabled).active,
.cm_select .dropdown-toggle:not(:disabled):not(.disabled):active,
.cm_select .show > .dropdown-toggle,
.cm_select .dropdown-toggle:focus {
  color: #000;
  background-color: #fff;
  border-color: #fff;
  text-align: left;
}

.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1) !important;
}

.cm-select .dropdown-item:focus,
.cm_select .dropdown-item:hover {
  color: #06a8f3;
  text-decoration: none;
  background: none;
}

label.sub {
  font-size: 1.2rem;
  font-weight: bold;
}

.modal-header .close {
  /* height: 28px; */
  width: 28px;
  font-size: 46px;
  color: #347bde;
  padding: 0px 1px;
  margin: -3rem 0rem -3rem auto;
  border-radius: 100%;
  opacity: 6;
  font-weight: 100;
  outline: none;
}

.project-information {
  display: flex;
  grid-gap: 50px;
  gap: 50px;
}

.project-summary {
  width: 50%;
}

.project-summary-detail {
  background-color: #8080807a;
  border-radius: 20px;
  padding: 5% 5%;
}

.cm_overflow_project {
  height: 500px;
  overflow-y: auto;
  overflow-x: hidden;
}

.cm_overflow_project_members {
  height: 530px;
  overflow-y: auto;
  overflow-x: hidden;
}

.cm_overflow_project_employee {
  height: 500px;
  overflow-y: auto;
  overflow-x: hidden;
}

.cm_overflow_device {
  max-height: 650px;
  overflow-y: auto;
  overflow-x: hidden;
}

.cm_submit.btn {
  width: 168px;
  height: 40px;
}

.profile_user span {
  border-radius: 11px;
  display: inline-block;
  padding-right: 115px;
}

.cm_drop.sm_drop {
  right: 25px !important;
  top: 15px !important;
}

.user_profile .dropdown-menu {
  left: auto !important;
  right: 19px !important;
  transform: none !important;
  top: 40px !important;
  border: none;
  border-radius: 11px;
  /* width: 176px; */
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);
}

.cm_list {
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);
  transition: 0.3s;
  background: #fff;
  border-radius: 11px;
  border: none;
  width: 128px;
  height: 50px;
  position: absolute;
  bottom: 0;
  right: 0px;
}

.react-calendar.cm_cal {
  width: 100%;
  border: none;
  border-bottom: 20px solid #2d6bce;
  background: #fff;
  border-radius: 11px;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);
  padding: 15px;
}

.cm_cal .react-calendar__navigation {
  border-bottom: 1px solid #cdcdcd;
  margin-left: -15px;
  margin-right: -15px;
}

.cm_cal .react-calendar__tile {
  padding: 1.2em 0.5em !important;
  position: relative;
}

.cm_cal .react-calendar__tile--active {
  background: transparent !important;
}

.cm_cal .react-calendar__tile--active:after {
  content: "";
  position: absolute;
  width: 40px;
  height: 40px;
  background: #4ec6da;
  border-radius: 100%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}

.cm_cal .react-calendar__month-view__days__day--weekend:after {
  content: "";
  position: absolute;
  width: 40px;
  height: 40px;
  background: #7460ee;
  border-radius: 100%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}

.cm_cal .react-calendar__month-view__days__day--weekend {
  color: #fff !important;
}

abbr {
  position: relative;
  z-index: 9;
  text-decoration: none !important;
}

.react-calendar.cm_date {
  width: 100%;
  border: none;
  border-bottom: 20px solid #2d6bce;
  background: #fff;
  border-radius: 11px;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);
  padding: 15px;
}

.cm_date .react-calendar__navigation__label {
  font-weight: bold;
}

.cm_date .react-calendar__navigation {
  height: 44px;
  margin-bottom: 1em;
  color: #377efc;
  font-size: 17px;
  font-weight: bold;
}

.cm_date .react-calendar__tile--active {
  border-radius: 100%;
  background: #006edc;
  color: white;
}

.cm_date .react-calendar__month-view__days__day--weekend {
  color: #000 !important;
}

.cm_date .react-calendar__navigation {
  border-bottom: 1px solid #cdcdcd;
  margin-left: -15px;
  margin-right: -15px;
}

.react-calendar.holidays {
  margin-bottom: 30px;
  width: 100%;
  border: none;
  border-bottom: 20px solid #2d6bce;
  background: #fff;
  border-radius: 11px;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);
  padding: 15px;
}

.holidays .react-calendar__month-view__days__day--weekend {
  color: #000 !important;
}

.input_error .form-control {
  border-color: red;
}

.input_error .date-border,
.input_error .ant-select-selection {
  border-color: red;
}

.skill input {
  border-radius: 10px !important;
}

.cm_width_100 {
  max-width: 100% !important;
}

.image-upload {
  position: relative;
  max-width: 239px;
  height: 284px;
  background: #8d8d8d;
  border-radius: 11px;
  right: 0;
  object-fit: cover;
  top: 36px;
}

.cm_top_fix {
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  top: 15px;
  z-index: 999;
}

.cm_toll_tip {
  position: fixed;
  left: 31.5%;
  transform: translateX(-50%);
  top: 52%;
}

li.ant-select-dropdown-menu-item-disabled {
  color: rgba(0, 0, 0, 0.65);
  text-align: left;
  font-size: 15px;
  cursor: default !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

.image-upload img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 11px;
}

.image-edit {
  position: absolute;
  bottom: 0;
  right: 20px;
}

.image-preview {
  width: 192px;
  height: 192px;
  position: relative;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
}

.image-upload input {
  position: absolute;
  padding: 18px;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  opacity: 0;
  cursor: pointer;
  font-size: 0;
}

.img-edit {
  position: absolute;
  width: 40px;
  height: 40px;
  right: 0;
  bottom: 0;
}

.fa-camera {
  font-size: 29px;
  color: #fff;
  cursor: pointer;
}

.email .fa-trash-o {
  position: absolute;
  top: 14px;
  right: 126px;
  color: red;
  font-size: 21px;
  transform: translateY(-50%);
  z-index: 9;
  cursor: pointer;
}

.email .fa-ban {
  position: absolute;
  top: 17px;
  right: 126px;
  color: red;
  font-size: 21px;
  transform: translateY(-50%);
  z-index: 9;
  cursor: none;
}

.email li {
  position: relative;
  list-style-type: none;
}

.cm_email {
  margin: 0;
  padding: 0;
  list-style: none;
}

.cm_req_modal .modal-content {
  padding-bottom: 22px;
  padding-left: 10px;
  padding-right: 10px;
}

.sm_modal1 .ant-modal-content {
  padding: 0 0px !important;
}

ul.cm_email li > * {
  display: inline-block;
  width: auto;
  vertical-align: middle;
}

ul.cm_email li textarea {
  width: calc(100% - 158px);
  margin-right: 20px;
}

ul.cm_email li {
  margin-bottom: 56px;
}

.cm_swich_wrapper {
  width: 40px;
  height: 18px;
  position: relative;
}

span.cm_swich {
  width: 40px;
  height: 18px;
  position: absolute;
  background: red;
  border-radius: 20px;
  left: 0;
  top: 0;
}

span.cm_swich:after {
  content: "";
  position: absolute;
  width: 12px;
  height: 12px;
  background: #fff;
  border-radius: 100%;
  left: 4px;
  top: 50%;
  transform: translateY(-50%);
  transition: all 0.3s;
}

.cm_swich_wrapper input {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  cursor: pointer;
  z-index: 99;
  opacity: 0;
}

.cm_swich_wrapper input:checked + .cm_swich {
  background: #3379de;
}

.cm_swich_wrapper input:checked + .cm_swich:after {
  left: 23px;
}

.addemp {
  font-size: 58px;
  position: absolute;
  right: 122px;
  margin-top: -26px;
}

.cm_btn2 {
  width: 118px;
  background: white;
  color: #2d6bce;
}

.upload_icon {
  cursor: pointer;
}

.cm_btn3 {
  width: 118px;
}

.cm_search {
  padding-right: 147px;
}

.cm_search .search_btn {
  right: 15px;
  font-weight: bold;
  background: #2d6bce;
  color: #fff;
  position: absolute;
  border-radius: 7px;
  top: 0;
  width: 121px;
  height: 48px;
}

.cm_proj .fa-file-pdf-o,
.cm_proj .fa-file-image-o {
  font-size: 42px;
  color: #2d6bce;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);
  background: #fff;
  border-radius: 11px;
  padding: 34px;
  margin-bottom: 21px;
}

.edu .fa-trash-o {
  position: absolute;
  top: 15px;
  right: 21px;
  font-size: 19px;
  color: #ef534f;
}

.edu .fa-pencil {
  position: absolute;
  top: 15px;
  right: 55px;
  font-size: 19px;
  color: #2d6bce;
}

.error_msg {
  color: red;
  bottom: -22px;
}

.require_error_msg {
  color: red;
}

.sm_due {
  cursor: pointer;
}

.fa-pencil {
  color: #2d6bce;
}

span.cm_add {
  color: #2d6bce;
  right: 20px;
  position: absolute;
}

.add_emp label {
  font-size: 19px;
  display: inline-block;
  margin-bottom: 0.5rem;
}

.ant-select-selection {
  border-radius: 10px;
  min-height: 50px;
  padding-bottom: 15px;
  border: 1px solid #c4c9ce;
}

.ant-select-selection:focus {
  box-shadow: none;
}

.cm_radio_wrapper {
  display: inline-block;
  position: relative;
  padding-left: 50px;
  margin-right: 25px;
  margin-top: 25px;
  font-size: 18px;
  font-weight: bold;
}

.reimbursement-action-modal {
  width: 45%;
}

.cm_cursor {
  cursor: pointer;
}

span.cm_radio {
  position: absolute;
  left: 2px;
  width: 29px;
  height: 29px;
  background: #fff;
  border-radius: 100%;
  vertical-align: middle;
  top: -5px;
}

.cm_radio_wrapper input {
  position: absolute;
  width: 30px !important;
  height: 30px !important;
  left: 2px;
  top: -5px;
  z-index: 9;
  opacity: 0;
}

.cm_radio {
  position: absolute;
  left: 2px;
  width: 29px;
  height: 29px;
  background: #eaeaea;
  border-radius: 100%;
  border: 1px solid #bdbdbd;
}

span.cm_radio:after {
  content: "";
  width: 8px;
  height: 13px;
  border-right: 2px solid #fff;
  border-bottom: 2px solid #fff;
  position: absolute;
  transform: rotate(44deg);
  right: 9px;
  top: 5px;
  opacity: 0;
}

.cm_radio_wrapper input:checked + .cm_radio:after {
  opacity: 1;
}

.cm_radio_wrapper input:checked + .cm_radio {
  background: #3277de;
}

.add_clg label {
  font-size: 19px;
}

form.add_clg {
  padding: 0 8%;
}

.cm_device .fa-trash-o {
  position: relative;
  font-size: 19px;
  color: #ef534f;
  margin-left: 17 px;
}

.cm_device .fa-pencil {
  position: relative;
  font-size: 19px;
  color: #2d6bce;
}

.search_device {
  padding-right: 336px;
}

.search_device .search_btn1 {
  right: 205px;
  font-weight: bold;
  background: #2d6bce;
  color: #fff;
  position: absolute;
  border-radius: 7px;
  top: 0;
  width: 121px;
  height: 48px;
}

.search_device .search_btn2 {
  right: 15px;
  font-weight: bold;
  background: #2d6bce;
  color: #fff;
  position: absolute;
  border-radius: 7px;
  top: 0;
  width: 182px;
  height: 48px;
}

.device .cm_card {
  height: 116px;
}

.attendance_status .cm_card {
  height: 115px;
}

.attendance_status .cm_card1 {
  height: 115px;
  columns: #fff;
}

p.cnfrm_msg {
  font-size: 25px;
}

.cm_ongoing .ant-select-selection.ant-select-selection--single {
  color: #fff;
  height: 37px;
  border: none;
  border-radius: 7px;
  background: #4ec6da !important;
}

.cm_ongoing .ant-select-selection.ant-select-selection--single:hover,
.cm_ongoing .ant-select-selection.ant-select-selection--single:focus,
.cm_ongoing .ant-select-selection.ant-select-selection--single:active {
  color: #fff;
  height: 37px;
  border: none;
  outline: none !important;
  box-shadow: 0 !important;
  border-radius: 7px;
  background: #4ec6da;
}

.cm_ongoing .ant-select-selection__rendered,
.cm_reject .ant-select-selection__rendered,
.cm_approve .ant-select-selection__rendered,
.cm_onHold .ant-select-selection__rendered {
  top: 17px !important;
}

.cm_approve .ant-select-selection.ant-select-selection--single {
  background: #47be83;
  color: #fff;
  height: 37px;
  border: none;
  border-radius: 7px;
}

.cm_maintenance .ant-select-selection.ant-select-selection--single {
  background: brown;
  color: #fff;
  height: 37px;
  border: none;
  border-radius: 7px;
}

.cm_onHold .ant-select-selection.ant-select-selection--single {
  background: orange;
  color: #fff;
  height: 37px;
  border: none;
  border-radius: 7px;
}

.cm_reject .ant-select-selection.ant-select-selection--single:active,
.cm_reject .ant-select-selection.ant-select-selection--single:hover,
.cm_reject .ant-select-selection.ant-select-selection--single:focus {
  background: #ec4a45 !important;
  color: #fff;
  border: none;
  height: 36px;
  border-radius: 7px;
}

.cm_approve .ant-select-selection.ant-select-selection--single:active,
.cm_approve .ant-select-selection.ant-select-selection--single:hover,
.cm_approve .ant-select-selection.ant-select-selection--single:focus {
  background: #47be83 !important;
  color: #fff;
  height: 37px;
  border: none;
  border-radius: 7px;
}

.cm_maintenance .ant-select-selection.ant-select-selection--single:active,
.cm_maintenance .ant-select-selection.ant-select-selection--single:hover,
.cm_maintenance .ant-select-selection.ant-select-selection--single:focus {
  background: brown !important;
  color: #fff;
  height: 37px;
  border: none;
  border-radius: 7px;
}

.cm_onHold .ant-select-selection.ant-select-selection--single:active,
.cm_onHold .ant-select-selection.ant-select-selection--single:hover,
.cm_onHold .ant-select-selection.ant-select-selection--single:focus {
  background: orange !important;
  color: #fff;
  height: 37px;
  border: none;
  border-radius: 7px;
}

.cm_incomplete {
  background: #ec4a45;
  color: #fff;

  border-radius: 7px;
  /* width: 100px; */
}

.cm_onHoldProjects {
  background: orange;
  color: #fff;

  border-radius: 7px;
  width: 100px;
}

.cm_card.cm_leave_info {
  max-height: 454px;
  min-height: 96%;
}

.cm_ticket_btn.cm_leave_btn .btn {
  margin-bottom: 25px !important;
}

.btn.btn_cm_proj:hover {
  color: #fff !important;
}

.cm_ongoing_proj {
  background: #4ec6da;
  color: #fff;
  border-radius: 7px;
  width: 100px;
}

.cm_complete {
  background: #47be83;
  color: #fff;
  border-radius: 7px;
  width: 100px;
}

.cm_reject .ant-select-selection.ant-select-selection--single {
  background: #ec4a45;
  color: #fff;
  border: none;
  height: 36px;
  border-radius: 7px;
}

.cm_reject .ant-select-selection-selected-value,
.cm_ongoing .ant-select-selection-selected-value,
.cm_maintenance .ant-select-selection-selected-value,
.cm_onHold .ant-select-selection-selected-value,
.cm_approve .ant-select-selection-selected-value {
  top: -7px;
}

.cm_approved.ant-select-arrow {
  color: #fff;
}

.nav-tabs {
  border-bottom: none;
  /* margin-top: -63px; */
}

nav.tabsNew.nav.nav-tabs {
  margin-top: 0px;
}

.nav-tabs a {
  border-color: #347bde !important;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);
  color: #2d6bce !important;
  background: #fff !important;
  margin-bottom: 30px !important;
  margin-right: 9px !important;
  border-radius: 8px !important;
  min-width: 138px;
  text-align: center;
}

.attendance_tab .nav-tabs a {
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);
  color: #2d6bce !important;
  background: #fff !important;
  margin-bottom: 30px !important;
  margin-right: 55px !important;
  border-radius: 8px !important;
  width: 22%;
  border: none;
  text-align: center;
  margin-top: 77px;
}

.cm_ticket_status .modal-content {
  padding-bottom: 0px;
  width: 590px;
}

.nav-tabs .nav-link.active {
  color: #fff !important;
  background-color: #2d6bce !important;
  border-color: #2d6bce !important;
}

form.edit_appraisal {
  padding: 0 7%;
}

.btn-success:disabled {
  cursor: not-allowed;
}

.view .btn-success {
  width: 185px !important;
}

.view .btn-danger {
  width: 185px !important;
}

.btn-danger:disabled {
  cursor: not-allowed;
}

.view label {
  font-size: 19px;
  font-weight: bold;
}

.cm_bill .fa-file-pdf-o {
  right: 160px;
  font-size: 26px;
  position: relative;
  top: 12px;
}

.cm_bill .fa-download {
  position: relative;
  left: 160px;
  font-size: 26px;
  top: 12px;
}

.cm_edit .fa-pencil {
  font-size: 20px;
  margin-left: 17px;
}

.cm_edit .btn-danger {
  margin-left: 26px;
}

.edit_appraisal label {
  font-size: 19px;
  font-weight: bold;
}

.edit_appraisal .btn {
  width: 83px;
  cursor: pointer;
}

.due_appraisal .btn-primary {
  width: 103px;
}

.due_appraisal label {
  font-size: 20px;
  color: #000;
}

.due_appraisal p {
  color: #00000073;
  font-weight: bold;
  font-size: 18px;
  word-break: break-all;
}

.cm_overflow_reimbur {
  max-height: 268px;
  overflow: auto;
}

.salary_margin {
  margin: 0 1rem 0 auto;
}

.reimbur_info .ant-modal-content {
  width: 414px;
  /* height: 443px; */
  position: relative;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-radius: 11px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  pointer-events: auto;
}

.pdf_reimbur_info .ant-modal-content {
  width: 1000px;
  height: 1000px;
  position: relative;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-radius: 11px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  pointer-events: auto;
}

.cm_date a {
  color: red;
}

.cm_appraisal .fa-pencil {
  font-size: 20px;
  margin-left: 17px;
  color: #2d6bce;
  cursor: pointer;
}

.cnf_msg .btn-primary {
  width: 200px;
}

.cm_leave .btn-danger {
  width: 75px;
}

.cm_leave .btn.btn-success {
  width: 75px;
}

form.assign_device {
  padding: 0 5%;
}

.cm_row .col1 {
  color: #000;
  font-size: 19px;
  margin-bottom: 21px;
}

.cm_row .col2 {
  /* word-break: break-all; */
  color: rgba(80, 77, 77, 0.747);
  font-size: 19px;
  margin-bottom: 21px;
}

.cm_row .col3 {
  color: rgba(80, 77, 77, 0.747);
  font-size: 19px;
}

.approve_leave {
  padding: 0 40px;
}

.grant_access_email {
  padding: 0 40px;
  margin-bottom: 60px;
}

form.grant_access {
  padding: 0 7px;
}

.approve_leave h3 {
  position: relative;
  bottom: 16px;
}

.approve_leave p {
  color: #00000085;
  font-size: 19px;
  font-weight: bold;
}

.approve_leave label {
  font-weight: bold;
  font-size: 20px;
}

.approve_leave time {
  font-weight: bold;
  font-size: 18px;
  color: #00000085;
}

.approve_leave .btn-success {
  margin-top: 29px;
  width: 151px;
  margin-right: 24px;
}

.delete_dept {
  background: #f15761;
  border-color: #f15761;
}

.delete_dept:hover {
  background: #f15761 !important;
  border: none !important;
}

button.btn.btn-primary.resolve_ticket {
  width: 131px;
}

.approve_leave .btn-danger {
  margin-top: 29px;
  width: 151px;
}

/* .modal-content {
  position: absolute !important;
} */

.leave-button {
  display: flex;
  justify-content: end;
  flex-direction: row;
}

ul.cm_proj {
  width: calc(100% - 50px);
  margin: 0;
  padding: 10px;
  overflow-x: auto;
  white-space: nowrap;
}

.cm_proj li {
  display: inline-block;
  margin-right: 19px;
}

.cm_radio_3_wrapper {
  display: block;
  position: relative;
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 10px;
  padding: 0px 15px;
}

span.cm_radio_3 {
  position: absolute;
  height: 17px;
  background: #fff;
  vertical-align: middle;
  top: 4px;
}

.cm_radio_3_wrapper input {
  position: absolute;
  width: 17px !important;
  height: 17px !important;
  top: 0px;
  z-index: 9;
  opacity: 0;
}

.cm_radio_3 {
  position: absolute;
  width: 17px;
  height: 17px;
  background: #eaeaea;
  border: 1px solid #bdbdbd;
}

.cm_btn_status .btn-primary:hover {
  color: #347bde !important;
}

span.cm_radio_3:after {
  content: "";
  width: 5px;
  height: 8px;
  border-right: 2px solid #fff;
  border-bottom: 2px solid #fff;
  position: absolute;
  transform: rotate(44deg);
  right: 5px;
  top: 2px;
  opacity: 0;
}

.cm_radio_3_wrapper input:checked + .cm_radio_3:after {
  opacity: 1;
}

.cm_radio_3_wrapper input:checked + .cm_radio_3 {
  background: #3277de;
}

.submit_button_margin {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  grid-gap: 1rem;
  gap: 1rem;
}

.leave_button_margin {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 1rem;
  gap: 1rem;
}

.changePassSuccess {
  color: #2ea216;
  padding-right: "30px";

  margin-right: "30px";
}

.cm_doc.cm_doclist li {
  padding-top: 7px !important;
}

.checkboc_cross {
  display: inline-block;
  text-align: center;
  width: 42px;
}

.proj_name {
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);
  background: #fff;
  border-radius: 11px;
  padding: 34px;
  margin-bottom: 21px;
}

.proj_member li {
  min-width: 95px;
  display: inline-block;
  border-right: 1.5px solid rgba(0, 0, 0, 0.07);
  border-bottom: 1.5px solid rgba(0, 0, 0, 0.07);

  padding-right: 10px;
  padding-top: 10px;
  text-align: center;
  padding-left: 14px;
}

ul.proj_member {
  width: auto;
  margin: 0;
  padding: 10px;
  overflow-x: auto;
  /* white-space: nowrap; */
  display: inline-block;
}

.proj_member {
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);
  background: #fff;
  border-radius: 11px;
  padding: 34px;
  margin-bottom: 21px;
}

.table thead th {
  vertical-align: top;
}

.ticket_table .table td {
  padding: 6px !important;
  vertical-align: top;
}

ul.dropdown_wrapper {
  margin: 0;
  padding: 0;
}

.cm_radio_2_wrapper {
  display: block;
  position: relative;
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 10px;
  padding: 0px 15px;
}

span.cm_radio_2 {
  position: absolute;
  right: -6px;
  height: 17px;
  background: #fff;
  border-radius: 100%;
  vertical-align: middle;
  top: 4px;
}

span.cm_radio_2.active {
  background: red;
}

.cm_table_dorpdwon .dropdown-menu {
  transform: none !important;
  top: 45px !important;
  left: auto !important;
  right: 23px !important;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1) !important;
  background: #fff !important;
  border-radius: 5px !important;
  border: none;
}

.cm_radio_2_wrapper input {
  position: absolute;
  width: 17px !important;
  height: 17px !important;
  right: -6px;
  top: 0px;
  z-index: 9;
  opacity: 0;
}

.cm_radio_2 {
  position: absolute;
  width: 17px;
  height: 17px;
  background: #eaeaea;
  border-radius: 100%;
  border: 1px solid #bdbdbd;
}

span.cm_radio_2:after {
  content: "";
  width: 5px;
  height: 8px;
  border-right: 2px solid #fff;
  border-bottom: 2px solid #fff;
  position: absolute;
  transform: rotate(44deg);
  right: 5px;
  top: 2px;
  opacity: 0;
}

.cm_radio_2_wrapper input:checked + .cm_radio_2:after {
  opacity: 1;
}

.cm_radio_2_wrapper input:checked + .cm_radio_2 {
  background: #3277de;
}

.cm_table_dorpdwon .dropdown-toggle:before {
  content: "";
  position: absolute;
  right: 0;
  top: 15px;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid white;
}

.cm_table_dorpdwon .dropdown-toggle {
  border: 0;
  min-width: 100px;
  text-align: left;
  position: relative;
}

.cm_selectStatus .ant-select-arrow .ant-select-arrow-icon svg {
  right: -12px;
  content: "";
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #fff;
  position: absolute;
  top: 0;
}

.cm_selectStatus .ant-select-selection {
  display: block;
  color: #fff;
  box-sizing: border-box;
  background-color: #347bde !important;
  margin-right: 15px;
  border-top-width: 1.02px;
  border-radius: 7px;
  top: 9px;
}

a.cm_pos_ab {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}

.cm_dept_dots {
  /* display: inline-block; */
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  max-width: 266px;
}

form.add-new-dept {
  padding-right: 17%;
  padding-left: 8%;
  height: auto;
  /* width: 505px; */
}

.add-new-dept h3 {
  bottom: 44px;
  position: relative;
}

.add-new-dept label {
  font-size: 1.3rem;
  position: relative;
  bottom: 6px;
}

.dept_card {
  margin-bottom: 25px;
  border: 0;
  min-height: 360px;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);
  background: #fff;
  border-radius: 10px;
  position: relative;
}

.appraisal_edit .ant-modal-content {
  width: 384px;
  height: 299px;
  position: relative;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-radius: 11px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  pointer-events: auto;
}

.edit_appraisal.ant-calendar-today .ant-calendar-date {
  color: #fff !important;
  font-weight: 0 !important;
  border-color: #fff !important;
}

.dept_card ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.dept_card ul li {
  margin-bottom: 15px;
  position: relative;
  padding-right: 80px;
}

.cm_count {
  position: absolute;
  right: 0;
  bottom: -5px;
}

.cm_range {
  height: 8px;
  background: #bdbdbd;
  border-radius: 10px;
  position: relative;
  overflow: hidden;
}

.cm_range span {
  position: absolute;
  height: 100%;
}

.emp_count {
  font-weight: bold;
  font-size: 1.8rem;
  color: #212529;
}

.card.dept_card .btn {
  min-width: 82px;
}

.fa-envelope-o {
  text-align: center;
  font-size: 18px;
  font-weight: bold;
}

.mini_loader {
  width: 40px;
  height: 40px;
  border: 2px #347bde solid;
  border-radius: 50%;
  animation: rotation 1s ease-in-out infinite;
  margin: 30px auto;
  position: absolute;
  left: 75px;
  bottom: -8px;
}

.mini_loader_dept {
  width: 40px;
  height: 40px;
  border: 2px #347bde solid;
  border-radius: 50%;
  animation: rotation 1s ease-in-out infinite;
  margin: 30px auto;
  position: absolute;
  left: 70px;
  bottom: -12px;
}

.mini_loaderdoc {
  width: 30px;
  height: 30px;
  border: 2px #949494 solid;
  border-radius: 50%;
  animation: rotation 1s ease-in-out infinite;
  margin: 30px auto;
  position: absolute;
  left: 124px;
  top: 441px;
  bottom: -10px;
}

.mini_loader:after {
  width: 8px;
  height: 8px;
  background-color: rgb(0, 0, 0);
  border-radius: 100%;
  position: absolute;
  content: "";
}

.mini_loader_dept:after {
  width: 8px;
  height: 8px;
  background-color: rgb(0, 0, 0);
  border-radius: 100%;
  position: absolute;
  content: "";
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.proj_member .fa.fa-plus-circle {
  font-size: 3.5rem;
  cursor: pointer;
  left: 0px;
  color: #2125294d;
  position: relative;
}

.proj_participant li {
  padding: 0 14px;
  min-width: 95px;
  display: inline-block;
  border-right: 1.5px solid rgba(0, 0, 0, 0.07);
}

ul.proj_participant {
  width: auto;
  margin: 0;
  padding: 10px;
  overflow-x: auto;
  white-space: nowrap;
  display: inline-block;
}

.proj_participant {
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);
  background: #fff;
  border-radius: 11px;
  padding: 34px;
  margin-bottom: 21px;
}

.sm_search input.form-control {
  border: 0;
}

.sm_search .fa {
  position: absolute;
  right: 17px;
  top: 15px;
  color: #b9b9b9;
}

i.fa.fa-search {
  cursor: pointer;
}

.sm_search {
  position: relative;
}

.edu_details p {
  position: relative;
}

h3.cm_response {
  font-size: 15px;
  color: red;
}

button.ant-modal-close {
  outline: none;
}

.ant-select-selection__placeholder {
  font-size: 17px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.59) !important;
}

.ant-input:hover {
  border-color: none !important;
  box-shadow: none !important;
}

.ant-input:focus {
  border-color: none !important;
  box-shadow: none !important;
}

.edit_btn {
  font-size: 20px;
  color: #347bde;
  position: absolute;
  right: -40px;
  transform: translateY(-50%);
  z-index: 9;
  cursor: pointer;
}

.cm_card1 {
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);
  transition: 0.3s;
  background: #47be7e;
  border-radius: 11px;
  padding: 14px;
  color: #fff;
  font-size: 19px;
  position: relative;
  margin-bottom: 25px;
}

.cm_calender li small .activeMon {
  color: red;
}

span.activeMon {
  border: 2px solid;
  position: absolute;
  top: 0px;
  right: 0;
  display: inline-block;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);
  padding: 6px;
  border-radius: 100%;
  background: #f44336 !important;
}

.hour_count {
  font-weight: bold;
  font-size: 1.8rem;
  color: #fff;
}

.cm_dashboard_appraisal div {
  display: inline-block;
}

.appraisal_name {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
  border-radius: 7px;
  width: 205px;
  margin-right: 35px;
  padding: 9px;
  margin-bottom: 21px;
}

.cm_apprais_dots {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  max-width: 200px;
}

.delete_btn {
  font-size: 20px;
  color: #ef534f;
  right: -69px;
  position: absolute;
  transform: translateY(-50%);
  z-index: 9;
  cursor: pointer;
}

.activeMon {
  background: #47be7e !important;
  color: #fff;
}

span.edit_info {
  position: absolute;
  right: 0;
  font-size: 23px;
  color: #347bde;
  cursor: pointer;
  z-index: 9;
}

.cm_btn2 {
  background: #fff;
  color: #347bde;
}

.profile_user.dropdown-toggle.btn.btn-primary {
  color: #000 !important;
  background-color: none !important;
  border-color: none !important;
}

.btn-primary:hover {
  color: #000 !important;
  background: #2d6bce;
  border-color: #2d6bce !important;
}

.department_btn .btn-primary:hover {
  color: #347bde !important;
  background: #c8e1f5 !important;
  border: 0;
}

.ant-select-selection-selected-value {
  color: rgba(0, 0, 0, 0.59);
  font-weight: 500;
  display: block;
  font-size: 17px;
  text-align: center;
  left: 12px;
  position: absolute;
  opacity: 1;
}

.list_black .ant-select-selection-selected-value {
  color: #000 !important;
  font-weight: bold !important;
}

.fa-times-circle {
  font-size: 19px;
  color: #00000073;
  font-weight: 600;
}

.btn:focus {
  box-shadow: none !important;
}

span.add_btn {
  position: absolute;
  right: -40px;
  font-size: 23px;
  color: #347bde;
  z-index: 9;
  cursor: pointer;
}

.emp_profile label {
  font-size: 20px;
}

.admin_emp_profile label {
  font-size: 20px;
}

.emp_profile p {
  font-size: 18px;
  color: #212529a6;
  word-break: break-all;
  /* padding-right: 39px; */
}

.admin_emp_profile p {
  font-size: 18px;
  color: #212529a6;
  word-break: break-all;
}

.search_bar_btn {
  right: 16px;
  font-weight: bold;
  background: #2d6bce;
  color: #fff;
  position: absolute;
  border-radius: 7px;
  top: 0;
  width: 121px;
  height: 42px;
}

span.cm_clear1 {
  position: absolute;
  width: 12px;
  right: 181px;
  top: 11px;
  cursor: pointer;
}

span.cm_clear3 {
  position: absolute;
  width: 12px;
  right: 210px;
  top: 11px;
  cursor: pointer;
}

span.cm_clearNew {
  position: absolute;
  width: 12px;
  top: 165px;
  right: 76px;
  cursor: pointer;
}

span.cm_clear4 {
  position: absolute;
  width: 12px;
  right: 184px;
  top: 11px;
  cursor: pointer;
}

.search_device_bar1 .form-control {
  height: 42px;
  padding-right: 30px;
}

.search_device_bar3 .form-control {
  height: 42px;
  padding-right: 30px;
}

.search_device_bar4 .form-control {
  height: 42px;
  padding-right: 30px;
}

.search_device_bar2 .form-control {
  height: 42px;
  padding-right: 30px;
}

.ant-calendar-picker-input {
  height: 41px;
}

.search_device_bar2 .select_date .ant-calendar-picker {
  position: absolute;
  max-width: 154px;
  left: 27px;
  border: 1px solid #c4c9ce;
  border-radius: 11px;
  cursor: pointer;
}

.select_date .ant-calendar-picker {
  position: absolute;
  max-width: 154px;
  left: 0px;
  border: 1px solid #c4c9ce;
  border-radius: 11px;
  cursor: pointer;
}

i.fa.fa-search {
  color: #00000073;
  cursor: pointer;
}

.search_device_bar .form-control {
  height: 42px;
  padding-right: 30px;
}

td.blue {
  cursor: pointer;
}

.search_bar {
  padding-right: 158px;
  padding-left: 192px;
}

.cancel_btn.btn {
  border: 1px solid #2d6bce;
  color: #2d6bce;
}

/* .attendance_view .cm_card{
  max-height:104px;;
} */

.attendance_view .nav-tabs {
  border-bottom: none;
  margin-top: 30px;
}

.attendance_view .nav-tabs .nav-link.active {
  width: 308px;
  height: 46px;
  color: #fff !important;
  background-color: #2d6bce !important;
  border-color: #2d6bce !important;
}

.attendance_view .nav-tabs .nav-link {
  width: 308px;
  color: #000 !important;
  border: 0;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);
  background: #fff;
}

.select_attendance_date .ant-calendar-picker {
  position: absolute;
  max-width: 117px;
  left: 156px;
  height: 45px;
  border: none;
  background: #fff;
  border-radius: 11px;
  cursor: pointer;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);
}

ul.cm_calender {
  width: calc(100% - 50px);
  margin: 0;
  padding: 10px;
  cursor: pointer;
  white-space: nowrap;
  overflow: auto;
}

.cm_calender li {
  display: inline-block;
  margin-right: 19px;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);
  padding: 12px;
  border-radius: 10px;
  width: 115px;
  text-align: center;
  position: relative;
  background: #fff;
}

.cm_calender li.active {
  background: #47bd7e;
}

.cm_calender li input {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  opacity: 0;
}

.cm_calender li p {
  margin: auto;
  word-break: break-all;
}

.cm_calender li small {
  position: absolute;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);
  padding: 10px;
  border-radius: 10px;
  left: 0;
  color: #000 !important;
  bottom: -31px;
  width: 100%;
  background: #fff !important;
  z-index: -1;
  padding-top: 17px;
}

ul.cm_quarterly {
  width: calc(100% - 50px);
  margin: 0;
  padding: 10px;
  white-space: nowrap;
  overflow: auto;
  cursor: pointer;
}

.cm_quarterly li {
  display: inline-block;
  margin-right: 19px;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);
  padding: 15px;
  border-radius: 10px;
  width: 115px;
  text-align: center;
  position: relative;
  background: #fff;
}

.cm_quarterly li input {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  opacity: 0;
}

.cm_quarterly li p {
  margin: auto;
  word-break: break-all;
}

.cm_quarterly li small {
  position: absolute;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);
  padding: 10px;
  border-radius: 10px;
  left: 0;
  bottom: -31px;
  width: 100%;
  background: #fff;
  z-index: -1;
  padding-top: 17px;
}

ul.cm_quarterly li {
  width: calc(100% / 4);
  min-width: 200px;
}

.cm_date_select {
  border-radius: 7px;
  width: 119px;
  font-size: 18px;
  color: #000;
  height: 43px;
  text-align: center;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);
  margin-left: 139px;
  position: absolute;
  background: #fff;
}

.cm_date_select .ant-select-selection-selected-value {
  float: center;
  max-width: 100%;
  position: absolute;
  top: 6px;
  font-weight: 500;
  right: 22px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.filters {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 20px;
  gap: 20px;
}

.ant-select-selection__rendered {
  top: 10px !important;
}

.date-border {
  border-radius: 10px;
  height: 50px;
  border: 1px solid #c4c9ce;
}

.cm_date_select .ant-select-arrow {
  top: 65%;
}

ul.cm_monthly {
  width: 100%;
  margin: 0;
  padding: 3px;
  white-space: nowrap;
  cursor: pointer;
  overflow: auto;
  padding-bottom: 40px;
}

ul.cm_monthly.leave_monthly_status {
  width: 105%;
}

.cm_calender li:hover {
  background: #47be7e7a;
}

.cm_monthly li {
  display: inline-block;
  margin-right: 8px;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);
  padding: 12px;
  border-radius: 10px;
  width: -29px;
  width: 22px;
  text-align: center;
  position: relative;
  background: #fff;
}

.cm_monthly li input {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  opacity: 0;
}

.cm_monthly li p {
  margin: auto;
  word-break: break-all;
}

.cm_monthly li small {
  position: absolute;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);
  padding: 10px;
  border-radius: 10px;
  left: 0;
  bottom: -31px;
  width: 100%;
  background: #fff;
  z-index: -1;
  padding-top: 17px;
}

ul.cm_monthly li {
  width: calc(100% / 14);
  min-width: 80px;
}

.fa-download.cm_doc_download {
  right: 20px !important;
}

.upload_doc {
  border: dashed 2px #949494;
  height: 200px;
  text-align: center;
  border-radius: 11px;
  position: relative;
  outline: none;
  cursor: pointer;
  background-size: cover !important;
}

.upload_doc_1 {
  background-size: 100% 100% !important;
  background-repeat: no-repeat;
}

.upload_dock {
  background-position: center !important;
  background-repeat: no-repeat !important;
  border: dashed 2px #949494;
  height: 200px;
  text-align: center;
  border-radius: 11px;
  position: relative;
  outline: none;
  cursor: pointer;
  background-size: 78px !important;
}

.cm_upload button.close {
  top: 53px !important;
  position: absolute;
  right: 9px;
  height: 46px;
  z-index: 500;
}

.fa-upload {
  top: 14px;
  position: absolute;
}

.cm_upload_name {
  display: inline-block;
  width: calc(100% - 25px);
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  vertical-align: middle;
  margin-left: 32px;
  margin-top: 7px;
}

.cm_upload .modal-content {
  padding-top: 0px;
  padding-bottom: 37px;
}

.cm_upload .modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
  padding: 23px 50px;
}

.cm_upload label {
  font-size: 19px;
}

.upload_doc p {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  margin: 0;
  font-size: 18px;
  font-weight: bold;
  word-break: break-all;
}

.upload_dock p {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  margin: 0;
  font-size: 18px;
  font-weight: bold;
  word-break: break-all;
}

.cm_upload .btn-primary {
  width: 122px;
}

.ant-select {
  display: block;
}

.Registration_error {
  color: red;
}

.fa.fa-bell-o {
  font-size: 22px;
  left: 226px;
  position: absolute;
  top: 25px;
}

.user_profile .dropdown-item {
  font-weight: bold;
  color: #5f6061;
}

.change_password .btn-primary {
  width: 200px;
  border-radius: 8px;
}

.ontrack {
  color: #7d66ef;
}

.tab_width {
  width: 161px !important;
}

.admin_emp_profile .btn-primary {
  background: #347bde !important;
  width: 259px !important;
  border-radius: 8px !important;
  cursor: pointer;
}

.cm_row_section.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -292px;
  margin-left: 32px;
  margin-top: 60px;
}

.cm_row_chart {
  margin-right: -24px !important;
}

.cm_row_section p {
  font-size: 17px;
  word-break: break-all;
}

.grant_access input {
  border-radius: 30px;
}

.grant_access button {
  width: 173px;
}

.grant_access .modal-content {
  width: 82%;
  padding-top: 45px;
  padding-bottom: 38px;
  border-radius: 11px;
  border: none;
  padding-top: 0;
}

.appraisal_info .ant-modal-content {
  width: 308px;
  height: 343px;
  position: relative;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-radius: 11px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  pointer-events: auto;
}

.grant_access .modal-header .close {
  height: 43px;
  width: 28px;
  font-size: 46px;
  color: #347bde;
  margin: -0.5rem 0.5rem -4rem auto;
  border-radius: 100%;
  opacity: 1;
  font-weight: 100;
}

.grant_access .cm_btn2 {
  width: 176px;
  background: white;
  color: #2d6bce;
}

.reim_action_date {
  margin-left: 74%;
}

.cm_appraisalModal .modal-content {
  width: 104% !important;
  height: 75% !important;
  position: absolute !important;
  padding-bottom: 18px;
  border-radius: 11px;
  border: none;
  padding-top: 41px;
}

.cm_appraisalModal .modal-header {
  padding: 1rem 0rem;
}

.select_date .ant-calendar-picker {
  position: absolute;
  max-width: 154px;
  left: 0;
  border: 1px solid #c4c9ce;
  border-radius: 11px;
  cursor: pointer;
}

.search_device_bar {
  padding-right: 19px;
  padding-left: 315px;
}

.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: none !important;
}

.cm_table_dorpdwon.dropdown {
  width: 146px;
}

.ant-modal.sm_modal {
  width: 55% !important;
  padding: 10px !important;
}

.ant-modal.sm_modal .ant-modal-body {
  padding: 29px 42px;
  font-size: 14px;
  line-height: 1.5;
  word-wrap: break-word;
}

.table .ant-select {
  display: inline-block;
}

i.fa.fa-pencil.mr-4.ml-2 {
  font-size: 20px;
}

.ant-modal.sm_modal1 {
  width: 50% !important;
  margin-top: 70px;
}

.table .btn-primary {
  background: #347bde;
  border-radius: 7px;
}

.eye_icon {
  position: absolute;
  width: 22px;
  top: 12px;
  right: 15px;
  cursor: pointer;
}

.error_eye {
  position: absolute;
  width: 22px;
  top: 12px;
  right: 45px !important;
  cursor: pointer;
}

.eye_icon svg {
  fill: #ced4da;
}

.eye_icon1 svg {
  fill: #ced4da;
}

.eye_icon1.slesh:after {
  content: "";
  position: absolute;
  width: 26px;
  height: 5px;
  background: #ced4da;
  left: -2px;
  top: 8px;
  transform: rotate(-45deg);
  border-radius: 3px;
}

.eye_icon2 svg {
  fill: #ced4da;
}

.eye_icon2.slesh:after {
  content: "";
  position: absolute;
  width: 26px;
  height: 5px;
  background: #ced4da;
  left: -2px;
  top: 8px;
  transform: rotate(-45deg);
  border-radius: 3px;
}

.eye_icon2 {
  position: absolute;
  width: 22px;
  top: 33px;
  right: 33px;
  cursor: pointer;
}

.eye_icon3 svg {
  fill: #ced4da;
}

.eye_icon3.slesh:after {
  content: "";
  position: absolute;
  width: 26px;
  height: 5px;
  background: #ced4da;
  left: -2px;
  top: 8px;
  transform: rotate(-45deg);
  border-radius: 3px;
}

.eye_icon3 {
  position: absolute;
  width: 22px;
  top: 33px;
  right: 33px;
  cursor: pointer;
}

.downloadIcon {
  display: inline-block;
  width: 21px;
}

.eye_icon.slesh:after {
  content: "";
  position: absolute;
  width: 26px;
  height: 5px;
  background: #ced4da;
  left: -2px;
  top: 8px;
  transform: rotate(-45deg);
  border-radius: 3px;
}

.cm_breadcrumb {
  position: absolute;
  top: 29px;
  left: 6px;
  font-size: 17px;
  color: #7b7b7b;
}

.cm_breadcrumb a {
  color: #000;
}

.form-control {
  height: 50px;
  font-size: 16px;

  background: #fff;
  border-radius: 11px;
}

table .dropdown-toggle {
  padding: 0 15px;
}

.cm_table_dorpdwon .dropdown-toggle:before {
  content: "";
  position: absolute;
  right: 12px;
  top: 8px;
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-top: 7px solid white;
}

.body_container .cm_loader {
  width: calc(100% - 330px);
  right: 0;
  left: auto;
}

h3.success_msg {
  font-size: 2rem;
  color: rgb(0, 102, 255);
}

h3.password_error {
  font-size: 1.5rem;
  color: red;
}

.department_btn .btn-primary {
  color: #347bde;
  background: #c8e1f5;
  border: 0;
  width: 80px;
  position: absolute;
  right: 100px;
  top: 105px;
  z-index: 9;
  border-radius: 8px;
}

.department_btn .btn-danger {
  border: 0;
  width: 80px;
  position: absolute;
  top: 105px;
  z-index: 9;
  right: 32px;
  color: #da1818;
  background: #f7b6b9;
  border-radius: 8px;
}

.department_img {
  height: 47px;
  width: 56px;
  left: 9px;
  top: 29px;
  background-size: cover;
  position: absolute;
  vertical-align: middle;
  border-style: none;
  width: 53px;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}

.early_coming {
  border-radius: 5px;
  position: absolute;
  top: -11px;
  right: 120px;
  color: #fff;
  background: transparent;
  width: 100%;
  height: 100%;
  border-radius: 11px;
}

.cm_card.cm_avghours {
  background-color: #f1524f;
  color: #fff;
}

.cm_card.cm_avghouryello {
  background-color: #f6b12b;
  color: #fff;
}

.cm_card.cm_avghourgreen {
  background-color: #47be7e;
  color: #fff;
}

.cm_card.cm_avghryellow {
  background-color: #f6b12b;
  color: #fff;
}

.cm_card.cm_avghryellow .emp_count {
  color: #fff;
}

.cm_card.cm_avghours .emp_count {
  color: #fff;
}

.cm_card.cm_avghourgreen .emp_count {
  color: #fff;
}

.cm_card.cm_avghouryello .emp_count {
  color: #fff;
}

.cm_card.cm_avghour {
  background-color: #47be7e;
  color: #fff;
}

.cm_card.cm_avghour .emp_count {
  color: #fff;
}

.cm_chart_owl.leave_chart {
  width: 220px;
}

.cm_card.cm_chart_info.leave_info_view {
  max-height: 458px;
  min-height: 94%;
}

.cm_count_no.cm_no_leave {
  right: 0;
}

p.sm_totalhr {
  color: #2d6bce;
  font-size: 20px;
}

.text-left.cm_totalhr {
  margin-bottom: -16px;
}

.cm_bold.sm_totalhr {
  background: red;
  width: 250px;
  color: #fff;
  border-radius: 10px;
  text-align: center;
  font-size: 30px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.cm_bold.sm_totalhr1 {
  background: #f6b12b;
  width: 250px;
  color: #fff;
  border-radius: 10px;
  text-align: center;
  font-size: 30px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.cm_bold.sm_totalhr2 {
  background: #65cca1;
  width: 250px;
  color: #fff;
  border-radius: 10px;
  text-align: center;
  font-size: 30px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.select_year_cal {
  width: 129px;
  bottom: 0px;
  border-radius: 10px;
}

.select_year_cal .ant-select-selection {
  margin-top: 4px !important;
}

.select_emp_leave {
  width: 200px;
  height: 42px;
}

.select_emp_leave .ant-select-selection--single {
  cursor: pointer;
}

ul.year_filter {
  padding: 0 !important;
}

ul.month_filter {
  padding-bottom: 2px !important;
}

.cm_style {
  position: absolute;
  left: 94px;
  top: 18px;
}

.ant-calendar-range-picker-separator {
  color: rgba(0, 0, 0, 0.79);
  margin-top: 2%;
}

i.fa.fa-pencil.edit_compoff {
  font-size: 20px;
}

input.year-input {
  cursor: pointer;
  border-radius: 10px;
  border: 1px solid #c4c9ce;
}

.select_year_cal.rang_pick.mr-3 {
  width: 234px;
  height: 45px !important;
  border: 1px solid #c4c9ce;
}

span.rang.select_year_cal.rang_pick.mr-3.ant-calendar-picker {
  margin-top: 5px;
}

span.rang.select_year_cal.rang_pick.mr-3.ant-calendar-picker:focus {
  box-shadow: none !important;
}

.year-input {
  border: 0;
  width: 110% !important;
  cursor: auto;
  outline: 0;
  height: 110% !important;
  color: rgba(0, 0, 0, 0.65);
  background: #fff;
  padding: 0 10px;
  border-radius: 4px;
  box-sizing: border-box;
}

span.atten_clear_year {
  position: absolute;
  top: 215px;
  cursor: pointer;
  z-index: 999;
  font-size: 13px;
  color: #838383;
  right: 57px;
}

span.card_leave_no {
  position: absolute;
  right: 13px;
  font-size: 2rem;
  top: 31px;
}

.input-icon:before {
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.cm_style p {
  color: #00000073;
  /* word-break: break-all; */
}

.cm_doc li {
  display: inline-block;
  margin-right: 19px;
  padding: 0px;
  background: #fdfdfd;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);
  border-radius: 11px;
  position: relative;
  width: 101px;
  padding-bottom: 30px;
  padding-top: 28px;
}

.cm_doc li svg {
  height: 55px;
  margin: 15px auto;
  width: auto;
  display: block;
}

.cm_doc li svg path {
  fill: #007bde;
}

.cm_doc p {
  display: inline-block;
  margin-right: 19px;
  padding: 10px;
  background: white;
  position: absolute;
  left: 0;
  bottom: 0;
  font-size: 12px;
  font-weight: 400;
  box-shadow: 0px -8px 10px -6px rgba(0, 0, 0, 0.1);
  margin: 0;
  width: 100%;
  border-radius: 0px 0px 0px 16px;
  line-height: 1;
}

.cm_doc span {
  display: inline-block;
  margin-right: 19px;
  padding: 13px;
  background: white;
  position: absolute;
  left: 0;
  top: 0;
  font-size: 12px;
  font-weight: 400;
  box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.1);
  margin: 0;
  width: 100%;
  border-radius: 0px 16px 0px 0px;
  line-height: 1;
}

.cm_doc h3 {
  font-size: 18px;
  color: #212529a6;
  word-break: break-all;
}

.cm_arrow {
  width: 12px;
  right: 11px;
  top: 1px;
  cursor: pointer;
  position: absolute;
  color: rgb(112, 112, 112);
  font-size: 15px;
  top: -9px;
}

.fa.fa-download {
  position: absolute;
  color: rgb(112, 112, 112);
  font-size: 15px;
  right: 33px;
  top: 5px;
}

span.cm_clear {
  position: absolute;
  width: 12px;
  right: 31px;
  top: 11px;
  cursor: pointer;
}

span.cm_clear svg {
  height: auto;
  width: 100%;
}

span.cm_clear1 svg {
  height: auto;
  width: 100%;
}

span.cm_clear svg path {
  fill: #707070;
}

span.cm_clear1 svg path {
  fill: #707070;
}

span.cm_clear3 svg path {
  fill: #707070;
}

span.cm_clear4 svg path {
  fill: #707070;
}

button.add_dept {
  position: absolute;
  top: 149px;
}

.cm_card.dashboard_info {
  overflow-y: auto;
  overflow-x: hidden;
}

.remaining_leaves {
  position: absolute;
  vertical-align: middle;
  border-style: none;
  background: #347bde;
  width: 42px;
  padding: 10px;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}

.late_comings {
  position: absolute;
  vertical-align: middle;
  border-style: none;
  background: #f6b22b;
  width: 42px;
  padding: 9px;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}

.late_comings.early_leave {
  background: #ef534f;
  position: absolute;
}

button.add_dept1 {
  position: absolute;
  top: 46px;
}

div.cm_arrow svg {
  height: auto;
  width: 100%;
}

div.cm_arrow svg path {
  fill: #707070;
}

.pagination li a {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #007bff;
  background-color: #fff;
  border: 1px solid #dee2e6;
}

.pagination {
  list-style: none;
}

.pagination li.active a {
  z-index: 1;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.ant-fullcalendar-last-month-cell .ant-fullcalendar-value,
.ant-fullcalendar-next-month-btn-day .ant-fullcalendar-value {
  display: none;
}

.my_calendar > div {
  border: 1px solid #d9d9d9;
  height: 95%;
  border-radius: 10px;
  margin-bottom: 30px;
  border-bottom: 20px solid #347bde;
  box-shadow: 0px 20px 10px -10px rgba(0, 0, 0, 0.15);
}

.my_calendar h5 {
  color: #347bde;
  font-size: 16px;
  text-align: center;
  padding: 10px;
  font-weight: bold;
}

.ant-fullcalendar-date {
  position: relative;
  cursor: pointer;
}

.ant-fullcalendar-column-header .ant-fullcalendar-column-header-inner {
  display: block;
  color: #000000d4;
  font-weight: normal;
}

.holiday_date {
  margin-top: 2px;
}

.ant-fullcalendar-content .innerDiv {
  cursor: pointer !important;
}

.events {
  position: absolute;
  background: #fff;
  bottom: 28px;
  /* left: 35px; */
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);
  /* min-width: 250px; */
  min-width: 148px;
  display: none;
  z-index: 999;
  border-radius: 10px 10px 10px 0;
  /* border-bottom: 15px solid #347bde; */
  border-bottom: 8px solid #347bde;
  right: -39px !important;
  box-shadow: 0px 0px 6px #a09e9e;
}

.ant-fullcalendar-date:hover .events {
  display: block;
}

.events p {
  margin: 0;
  /* padding: 20px 10px; */
  padding: 14px 10px;
  /* font-size: 20px; */
  font-size: 16px;
  font-weight: bold;
  /* word-break: break-all; */
}

.ant-fullcalendar-month-panel-selected-cell .ant-fullcalendar-value,
.ant-fullcalendar-selected-day .ant-fullcalendar-value {
  color: rgba(0, 0, 0, 0.65);
  background: rgba(0, 0, 0, 0);
}

.ant-fullcalendar-content {
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.orange .bage {
  position: absolute;
  width: 30px;
  /* height: 25px; */
  font-weight: bold;
  color: #fff !important;
  background: #f6b42c;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  cursor: default;
  border-radius: 100%;
}

td.ant-fullcalendar-cell.ant-fullcalendar-last-day-of-month.ant-fullcalendar-selected-day {
  cursor: default !important;
}

.greeen .bage {
  font-weight: bold;
  color: #fff !important;
  position: absolute;
  width: 30px;
  /* height: 25px; */
  background: #7865f1;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  cursor: default;
  border-radius: 100%;
}

.ant-fullcalendar-next-month-btn-day .ant-fullcalendar-date {
  display: none !important;
}

.ant-fullcalendar-value {
  border-radius: 100%;
  cursor: pointer;
}

.ant-fullcalendar-value:hover {
  background: red !important;
}

.cm_chart_owl {
  width: 250px;
  margin: 0 auto;
  position: relative;
}

.cm_chart_owl_val {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 220px;
  height: 220px;
  background: white;
  border-radius: 100%;
  text-align: center;
  line-height: 220px;
  font-size: 40px;
}

.cm_chart_owl_val1 {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 0px;
  height: 0px;
  margin-top: 26px !important;
  padding-top: 26px !important;
  background: white;
  border-radius: 100%;
  text-align: center;
  line-height: 220px;
  font-size: 40px;
}

.holiday_tab.nav-tabs {
  border-bottom: none;
  margin-top: 39px;
  right: 0;
  justify-content: flex-start;
}

.holiday_tab.nav-tabs a {
  border-color: #347bde !important;
  color: #347bde !important;
  background: #fff !important;
  margin-bottom: 30px !important;
  margin-right: 10px !important;
  margin-left: 3px;
  border-radius: 8px !important;
  width: 150px;
}

.ant-collapse-item {
  margin-bottom: 10px;
  border: 0px !important;
}

.ant-collapse-header {
  background: #fff;
  border-radius: 11px;
  margin-bottom: 4px;
  border: 0px;
  overflow: hidden;
  font-size: 17px;
  font-weight: bold;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.ant-collapse-content {
  background: #fff;
  border-radius: 11px;
  margin-bottom: 4px;
  border: 0px;
  overflow: hidden;
  font-size: 17px;
  font-weight: bold;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);
  margin-top: 10px;
  border-radius: 10px;
}

.cm_red .ant-collapse-content {
  border-bottom: 12px solid #ef534f;
}

.cm_green .ant-collapse-content {
  border-bottom: 12px solid #0062cc;
}

.cm_yellow .ant-collapse-content {
  border-bottom: 12px solid #f6b12b;
}

.cm_black .ant-collapse-content {
  border-bottom: 12px solid #4ec6da;
}

.cm_blue .ant-collapse-content {
  border-bottom: 12px solid #0062cc;
}

.text-cen {
  margin-right: 39%;
  text-align: center !important;
}

p.darkblue {
  font-size: 16px;
}

.cm_card.cm_pr.cm_pr1.cm_bold.cm_cardDashbord {
  padding-right: 19px;
  font-size: 14px;
  height: 125px;
}

.ant-collapse-borderless > .ant-collapse-item > .ant-collapse-content {
  background-color: transparent;
  border-top: 0;
  border-radius: 10px;
}

p.green.green1 {
  font-size: 16px;
}

.ant-collapse-borderless > .ant-collapse-item:last-child .ant-collapse-header {
  border-radius: 0;
  border-radius: 11px;
}

.activity_user h3 {
  font-size: 25px;
  color: #00000094;
  margin-top: 0;
  margin-bottom: 1em;
}

.cm_activity .btn-primary {
  border: 0;
  background: #cae3f7;
  border-radius: 8px;
  color: #347bde;
  font-weight: bold;
}

.fa-trash-o.add_dept {
  font-size: 33px;
  margin-top: 3px;
  position: absolute;
}

ul.cm_chart {
  margin: 0;
  padding: 0;
  list-style: none;
  margin-top: 40px;
}

.cm_chart li {
  position: relative;
  border-top: 2px solid #d0d0d0;
  padding: 15px 10px;
}

.cm_count_no {
  position: absolute;
  top: 50%;
  right: 10px;
  font-size: 16px;
  transform: translateY(-50%);
}

.cm_count_no span {
  padding-left: 4px;
  margin-left: 6px;
  border-left: 2px solid #d0d0d0;
}

.cm_ticketAction {
  cursor: pointer;
}

.cm_appraisal .red {
  cursor: pointer;
}

.cm_chart_info,
.dashboard_info {
  /* min-height: calc(93vh - 418px); */
  max-height: 531px;
  min-height: 100%;
}

button.btn.btn-primary.active {
  color: #fff !important;
  background-color: #2d6bce !important;
  border-color: #2d6bce !important;
}

.cm_ticket_btn .btn {
  font-size: 15px;
  border-color: #347bde !important;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);
  color: #2d6bce !important;
  background: #fff !important;
  margin-bottom: -60px !important;
  margin-right: 9px !important;
  border-radius: 8px !important;
  width: 138px;
  text-align: center;
}

.cm_ticket_btn .btn:hover {
  color: #000 !important;
  background-color: #2d6bce !important;
  border-color: #2d6bce !important;
}

.cm_category {
  top: 0;
  position: absolute;
  right: 0px;
  border-radius: 10px;
}

.cm_add_ticket {
  padding: 0px 40px;
}

.year-picker {
  position: relative;
  outline: none;
  width: 121px;
  height: 38px;
  list-style: none;
  font-size: 16px !important;
  text-align: left;
  background-color: none !important;
  border-radius: 11px;
  background-clip: padding-box;
  line-height: 1.5;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  border: 0px solid transparent !important;
  border-color: none !important;
}

span.error_msg_csv {
  position: absolute;
  right: 48px;
  color: red;
  top: 312px;
  margin-top: 4px;
  /* margin-bottom: 5px; */
}

span.deselect_emp {
  position: absolute;
  top: 2px;
  color: #bcbdbe;
  margin-left: 22px;
  cursor: pointer;
}

span.error_msg2 {
  color: red;
  margin-top: -24px;
  position: absolute;
}

.cm_ticket_status {
  padding: 0px 40px;
  font-weight: bold;
}

span.edit_log_view {
  position: absolute;
  right: 0;
  font-size: 24px;
}

span.edit_log_view_more {
  position: absolute;
  right: 74px;
  top: 26px;
  font-size: 24px;
}

span.edit_log {
  position: absolute;
  right: 60%;
  font-size: 19px;
}

span.delete_log {
  position: absolute;
  right: 55%;
  font-size: 19px;
}

.cm_card.cm_card_dept {
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);
  transition: 0.3s;
  background: #fff;
  border-radius: 11px;
  height: 152px;
  position: relative;
  margin-bottom: 25px;
}

.cm_ticket_status .modal-body {
  top: -44px;
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

p.cm_year {
  display: inline-block;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);
  padding: 12px;
  border-radius: 10px;
  width: 115px;
  text-align: center;
  position: absolute;
  background: #fff;
  right: 125px;
  top: 145px;
}

.cm_btn_status .btn {
  border: 0;
  width: 100px;
  border-radius: 8px;
  cursor: inherit;
}

.cm_btn_status .btn-primary {
  color: #ff9800;
  background: #ffc1074f;
}

.cm_btn_status .btn-primary:hover {
  color: #ff9800 !important;
  background: #ffc1074f !important;
}

.cm_btn_status .btn-success {
  background: #ccf2e5;
  color: #206b51;
}

.cm_btn_status .btn-danger {
  background: #f9d8d9;
  color: #bb1a1f;
}

.cm_graph canvas.chartjs-render-monitor {
  width: 300px !important;
  height: 300px !important;
}

.cm_user_img .btn-primary {
  width: 200px;
  left: 22px;
  background: #347bde;
  position: absolute;
}

.wrapper-class {
  padding: 0px !important;
}

span.tabtext {
  font-size: 14px;
  font-weight: bold;
}

.cm_textbox {
  margin-left: -17px;
}

.cm_user_img h5 {
  color: #347bde;
  margin-right: 129px;
  margin-top: 83px;
}

.cm_hoilday h3 {
  color: #347bde;
}

.sm_date li {
  position: relative;
  padding: 15px 10px;
  list-style: none;
}

.sm_date {
  font-size: 18px;
  font-weight: bold;
}

.sm_date span {
  position: absolute;
  left: 0;
  top: 15px;
}

.sm_date li p {
  margin: auto;
  padding-left: 30px;
}

.sm_date li span:after {
  content: "\2022";
  position: absolute;
  color: #347bde;
  font: 50px;
  left: -39px;
  top: -18px;
  font-size: 2.5rem;
}

.emp_profile .btn {
  /* position: absolute;
  transform: translateY(-50%); */
  z-index: 9;
  cursor: pointer;
  background-color: #347bde;
  border-color: #347bde;
  right: 0;
  color: white;
  width: 100px;
}

.emp_profile .btn-right {
  /* position: absolute;
  transform: translateY(100%); */
  z-index: 9;
  cursor: pointer;
  background-color: #347bde;
  border-color: #347bde;
  right: 0;
  color: white;
  width: 10rem;
  max-width: 10rem;
}

.dashboard-detail {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.cm_select_year .ant-select-arrow {
  color: #000;
}

.cm_select_year.ant-select.ant-select-enabled {
  position: absolute;
  top: -3px;
  right: 208px;
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.1);
  transition: 0.3s;
  background: #fff;
  border-radius: 11px;
  border: none;
  width: 128px;
  height: 45px;
}

.cm_project_table .ant-select-arrow {
  color: #fff !important;
}

.user_profile a {
  color: #5f6061;
  text-decoration: none;
  background-color: transparent;
}

td.ant-fullcalendar-cell.ant-fullcalendar-last-month-cell {
  opacity: 0;
}

.cm_leave_status span {
  position: absolute;
  margin-left: 9px;
  font-size: 22px;
  font-weight: bold;
}

.row-btn .cm_ticket_btn .btn {
  margin-bottom: 0 !important;
}

.cm_leave_status label {
  position: absolute;
  margin-left: 24px;
  font-size: 22px;
  font-weight: bold;
}

.cm_leave_status lable {
  font-size: 20px;

  color: rgba(0, 0, 0, 0.58);
}

.cm_border {
  border-left: 2px solid #d0d0d0;
}

.proj_detail label {
  font-size: 16px;
}

.proj_detail p {
  font-size: 14px;
  color: #212529;
  line-height: 20px;
  /* word-break: break-all; */
}

.proj-activity p {
  font-size: 14px;
  color: #212529;
  line-height: 20px;
}

.cm_break p {
  word-break: break-all;
}

.cm_break label {
  color: #000;
}

textarea.cm_descrip {
  min-height: 160px;
}

.ant-modal-close-x {
  color: #347bde !important;
  font-size: 19px;
}

.leave_content .modal-content {
  width: 630px;
  position: absolute !important;
}

.cancel_btn.btn:hover {
  color: #000 !important;
  background-color: rgba(24, 144, 255, 0.08) !important;
  border-color: #347bde !important;
}

i.fa.fa-sort-alpha-asc {
  cursor: pointer;
}

i.fa.fa-sort-alpha-desc {
  cursor: pointer;
}

.ant-calendar-picker-input {
  cursor: pointer;
}

.cm_selectStatus.ant-select.ant-select-enabled span.cm_radio_2 {
  display: none;
}

/* input.form-control:active {
  color: #495057;
  background-color: #fff;
  border-color:#2e81d4;
  outline: 0;
  box-shadow: none;

} */
.mini_loader_dept.log_loader {
  left: 296px;
}

.cm_style.design {
  left: 38px;
}

.fa-birthday-cake {
  font-size: 20px;
  color: #800000;
}

.selectDesig .ant-select-selection--multiple .ant-select-selection__rendered {
  height: auto;
  margin-bottom: 5px;
  margin-left: 5px;
}

.selectDesig .ant-select-selection__placeholder,
.ant-select-search__field__placeholder {
  font-size: 17px;
  color: rgba(0, 0, 0, 0.59) !important;
}

.input_error .selectDesig {
  border-color: red;
}

h3.total-Device {
  margin-top: 20px;
}

span.date-border.rang.rang_pick.mr-3.ant-calendar-picker {
  margin-top: 3px;
  width: 250px;
}

.ant-calendar-picker:focus .ant-calendar-picker-input:not(.ant-input-disabled) {
  border: none !important;
  outline: 0;
  box-shadow: 0;
}

@font-face {
  font-family: Clip;
  src: url("https://acupoftee.github.io/fonts/Clip.ttf");
}

.sign {
  font-size: 23px;
}

@keyframes blink {
  0%,
  22%,
  36%,
  75% {
    color: #4ec6da;
    text-shadow: 0 0 0.6rem #ffe6ff, 0 0 1.5rem #ff65bd,
      -0.2rem 0.1rem 1rem #ff65bd, 0.2rem 0.1rem 1rem #ff65bd,
      0 -0.5rem 2rem #ff2483, 0 0.5rem 3rem #ff2483;
  }

  28%,
  33% {
    color: #ff65bd;
    text-shadow: none;
  }

  82%,
  97% {
    color: #ff2483;
    text-shadow: none;
  }
}

@keyframes blink {
  0%,
  22%,
  36%,
  75% {
    color: #4ec6da;
    text-shadow: 0 0 0.6rem #ffe6ff, 0 0 1.5rem #ff65bd,
      -0.2rem 0.1rem 1rem #ff65bd, 0.2rem 0.1rem 1rem #ff65bd,
      0 -0.5rem 2rem #ff2483, 0 0.5rem 3rem #ff2483;
  }

  28%,
  33% {
    color: #ff65bd;
    text-shadow: none;
  }

  82%,
  97% {
    color: #ff2483;
    text-shadow: none;
  }
}

.flicker {
  animation: shine 2s forwards, blink 3s 2s infinite;
}

.fast-flicker {
  animation: blink 10s 1s infinite;
}

.mini_loader_dept.emp_loader.mt-5 {
  top: -47px;
  right: 150px;
}

p.purple.cm_birthday {
  font-weight: bold;
  font-size: 23px;
  margin-bottom: 0;
  text-shadow: 5px 8px 3px rgba(103, 58, 183, 0.31);
}

i.fa.fa-id-badge {
  font-size: 28px;
}

.form-control:active {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.cm_hoilday .btn.btn-primary {
  top: 141px;
  position: absolute;
  right: 45px;
  width: 147px;
  border-radius: 9px;
}

.ant-calendar-range-picker-input {
  cursor: pointer;
}

.pagination li.disabled a {
  cursor: not-allowed;
  z-index: 1;
  color: rgba(0, 0, 0, 0.25);
  background: #f5f5f5;
  border-radius: 0;
}

.edit_holiday {
  font-size: 20px;
  color: #347bde;
  position: absolute;
  left: 60%;
  top: 50%;
  transform: translateY(-50%);
  z-index: 9;
  cursor: pointer;
}

ul.cm_quarterly li:hover {
  background: #47be7e7a;
}

ul.cm_monthly li:hover {
  background: #47be7e7a;
}

.delete_holiday {
  font-size: 20px;
  color: #347bde;
  position: absolute;
  left: 63%;
  top: 50%;
  transform: translateY(-50%);
  z-index: 9;
  cursor: pointer;
}

.cm_claim p {
  font-size: 17px;
  color: #212529a6;
}

.cm_bordr {
  border-right: 2px solid #d0d0d0;
}

.cm_claim h4 {
  margin-top: 75px;
}

.fa-pencil {
  cursor: pointer;
}

.fa-trash-o {
  cursor: pointer;
}

.cm_claim .btn {
  width: 209px;
}

.close:not(:disabled):not(.disabled):hover {
  opacity: none !important;
}

.ant-select-focused .ant-select-selection,
.ant-select-selection:focus,
.ant-select-selection:active {
  border-color: none;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: none;
}

.cm_btn2:hover {
  color: #000 !important;
  background-color: rgba(24, 144, 255, 0.08) !important;
  border-color: #347bde !important;
}

i.fa.fa-exclamation-circle {
  color: #9a1717;
  cursor: pointer;
  position: absolute;
  top: 20%;
  right: 14px;
  font-size: 27px;
}

span.cm_swich1 {
  width: 40px;
  height: 18px;
  position: absolute;
  background: rgba(0, 0, 0, 0.45);
  border-radius: 20px;
  cursor: not-allowed;
  left: 0;
  top: 0;
}

span.cm_swich1:after {
  content: "";
  position: absolute;
  width: 12px;
  height: 12px;
  background: #fff;
  border-radius: 100%;
  left: 23px;
  cursor: not-allowed;
  top: 50%;
  transform: translateY(-50%);
  transition: all 0.3s;
}

/* .form-control:hover {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0,123,255,.25);
} */
.employee_cal .ant-fullcalendar-date {
  cursor: default;
}

.close {
  outline: none;
}

.add_empBtn {
  background: #2d6bce;
  position: absolute;
  top: 1px;
  text-align: center;
  border-radius: 6px;
  /* height: 40px; */
  right: 17px;
}

.cm_close {
  width: 21px;
  right: 20px;
  cursor: pointer;
  position: absolute;
  color: #fff;
  font-size: 24px;
  top: 16px;
}

.sidebar li:hover {
  background: #2d6bce;
}

.confirmation .cm_btm3 {
  width: 85px !important;
}

.select_date .ant-calendar-picker {
  position: absolute;
  max-width: 154px;
  left: 130px;
  border: 1px solid #c4c9ce;
  border-radius: 11px;
  cursor: pointer;
}

span.errorMsgTooltip {
  position: absolute;
  background: #9a1717;
  color: #fff;
  left: -148px;
  top: 0;
  padding: 12px;
  border-radius: 5px;
  border: 1px solid #651e1e;
  width: 139px;
  font-size: 13px;
  font-weight: 300;
}

.cm_approve .ant-select-selection-selected-value {
  color: #f5f5f5;
  font-weight: 500;
  display: block;
  font-size: 15px;
  text-align: center;
  left: 6px;
  position: absolute;
  opacity: 1;
}

.cm_maintenance .ant-select-selection-selected-value {
  color: #f5f5f5;
  font-weight: 500;
  display: block;
  font-size: 15px;
  text-align: center;
  left: 6px;
  position: absolute;
  opacity: 1;
}

.cm_onHold .ant-select-selection-selected-value {
  color: #f5f5f5;
  font-weight: 500;
  display: block;
  font-size: 15px;
  text-align: center;
  left: 6px;
  position: absolute;
  opacity: 1;
}

.cm_ongoing .ant-select-selection-selected-value {
  color: #f5f5f5;
  font-weight: 500;
  display: block;
  font-size: 15px;
  text-align: center;
  left: 6px;
  position: absolute;
  opacity: 1;
}

.cm_reject .ant-select-selection-selected-value {
  color: #f5f5f5;
  font-weight: 500;
  display: block;
  font-size: 15px;
  text-align: center;
  left: 6px;
  position: absolute;
  opacity: 1;
}

.ant-modal.attend_modal {
  width: 40% !important;
}

.cm_reject .ant-select-arrow .ant-select-arrow-icon {
  display: none;
  color: #fff;
}

.cm_ongoing .ant-select-arrow .ant-select-arrow-icon {
  display: block;
  color: #fff;
}

.cm_approve .ant-select-arrow .ant-select-arrow-icon {
  display: block;
  color: #fff;
}

.cm_maintenance .ant-select-arrow .ant-select-arrow-icon {
  display: block;
  color: #fff;
}

.cm_onHold .ant-select-arrow .ant-select-arrow-icon {
  display: block;
  color: #fff;
}

.errorMsgTooltip:after {
  content: "";
  position: absolute;
  width: 12px;
  height: 12px;
  border-right: 1px solid #651e1e;
  background: #9a1717;
  right: -7px;
  top: 14px;
  transform: rotate(45deg);
}

i.fa.fa-trash-o.delet_proj {
  font-size: 33px;
  position: absolute;
  top: 76px;
}

.remb_select .ant-select-selection-selected-value {
  font-size: 14px;
  color: #fff;
}

.remb_select .ant-select-arrow .ant-select-arrow-icon {
  display: block;
  color: #fff;
}

.select__date {
  /* padding-left: 295px; */
}

.cm_overflow_reason {
  max-height: 401px;
  overflow: auto;
  overflow-x: hidden;
}

/* ----------tooltip--------  */
.tooltip {
  position: relative;
  border: none;
  z-index: unset !important;
  font-size: 11px;
  font-weight: bold;
  font-family: circe, sans-serif !important;
  opacity: 1 !important;
  display: inline-block !important;
  color: #627985;
}

.tooltip .tooltiptext {
  visibility: hidden;
  word-break: break-all;
  border-radius: 10px 10px 10px;
  /* width: 250px; */
  min-width: 150px;
  background-color: #fff !important;
  color: #000 !important;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  padding: 8px;
  box-shadow: 0px 0px 6px #a09e9e;
  font-size: 11px;
  font-weight: 500;
  z-index: 9999;
  margin-left: -90px !important;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

p.card_num2 {
  font-size: 2rem;
}

.picker-panel {
  position: absolute;
  top: 32px;
  width: 240px;
  z-index: 999;
  height: 220px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  background-color: white;
  transition: all 0.3s;
}

/* ---------------------media start -------------- */

.leave-tab {
  margin-top: 2rem;
}

@media (max-width: 2050px) {
  .col-4.activity_user {
    margin-left: 64px;
  }

  .cm_card p {
    font-size: 14px;
    margin-bottom: 0px;
  }

  h3.total-Device {
    font-size: 14px;
    margin-bottom: 0;
    margin-top: 0;
  }

  i.anticon.anticon-close.ant-modal-close-icon {
    margin-left: 42px;
  }

  span.card_leave_no {
    font-size: 24px;
  }

  .rang .ant-calendar-picker-input {
    cursor: pointer;
    width: 231px;
  }

  .cm_chart_owl.leave_chart {
    width: 165px;
  }

  p.card_num2 {
    position: absolute;
    font-size: 24px;
    right: 5px;
    margin-top: 27px;
  }

  .cm_card.cm_pr.cm_pr2.cm_bold.card5.cm_cardDashbord {
    height: 124px;
  }

  .darkblue {
    font-size: 15px;
  }

  .fa-pencil.edit_leave {
    cursor: pointer;
    position: absolute;
    right: 17px;
  }

  span.card_num.card_leave_no {
    right: 10px;
    font-size: 27px;
  }

  .sm_hours {
    font-size: 16px;
    margin-bottom: 0;
    margin-top: 14px;
  }

  .col_leave_border .cm_leave_status label {
    font-size: 18px;
    margin-left: 17px;
  }

  .col_leave_border.cm_status_color {
    left: 10px;
  }

  .leave_tab_button {
    /* left: 420px; */
    border-color: #347bde !important;
    box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);
    color: #2d6bce !important;
    background: #fff !important;
    margin-bottom: -38px !important;
    margin-right: 9px !important;
    margin-left: 290px !important;
    border-radius: 8px !important;
    width: 8rem;
    height: 38px;
    text-align: center;
    top: 1px;
  }

  .yellow_btn {
    width: 101px;
    font-size: 14px;
    height: 37px;
    margin-left: -35px;
  }

  .blue_btn {
    margin-right: -60px;
    width: 95px;
    font-weight: bold;
    font-size: 14px;
    height: 37px;
  }

  .leave_tab_button .ant-select-selection__placeholder {
    font-size: 14px;
    color: rgb(45, 107, 206) !important;
    left: 25px;
    top: 8px;
    font-weight: bold;
  }

  .cm_status_color label {
    font-size: 15px;
  }

  .cm_status_color span {
    font-size: 23px;
    font-weight: bold;
  }

  .cm_dept_dots {
    max-width: 204px;
  }

  ant-calendar-picker-input.ant-input {
    font-size: 15px;
  }

  .select_attendance_date .ant-calendar-picker {
    left: 167px;
  }

  .cm_ticket_btn .btn {
    font-size: 14px;
    /* width: 117px !important; */
    margin-bottom: -62px !important;
  }

  .cm_atten_date.ant-select.ant-select-enabled {
    right: 11px;
  }

  .ant-fullcalendar-disabled-cell .ant-fullcalendar-value {
    width: 35px !important;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .add_empBtn.add1 {
    width: 173px !important;
  }

  .add_empBtn {
    font-size: 15px;
    width: 133px !important;
  }

  .cm_card.dashboard_card {
    padding: 23px !important;
  }

  .cm_card.dashboard_card .card_num {
    font-size: 32px;
    right: 40px;
    top: 65px;
  }

  .cm_no {
    font-size: 3rem;
  }

  .ant-fullcalendar {
    height: 283px;
  }

  .cm_leave_status lable {
    font-size: 18px;
    margin-left: -44px;
  }

  .cm_leave_status span {
    margin-left: 3px;
  }

  .card_num {
    font-size: 32px;
  }

  .sm_card .cm_card {
    min-height: 192px !important;
  }

  .ant-modal.sm_modal {
    width: 72% !important;
  }

  .cm_chart_owl_val {
    left: 45%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 152px;
    height: 152px;
    line-height: 157px;
    font-size: 40px;
  }

  .cm_user_proj_img img {
    width: 100%;
    height: 240px;
    object-fit: cover;
  }

  .late_comings {
    top: 28px;
  }

  .remaining_leaves {
    top: 28px;
  }

  .cm_select_year.ant-select.ant-select-enabled {
    width: 131px !important;
  }

  .cm_date_select {
    margin-left: 160px;
  }

  .cm_chart_owl .chartjs-render-monitor {
    width: 177px !important;
    height: 176px !important;
  }

  .cm_chart_owl {
    width: 195px;
    margin: 30px auto;
  }

  .card_time {
    position: absolute;
    right: 15px;
    top: 50%;
    font-size: 25px;
  }

  .cm_card h4 {
    font-size: 21px;
  }

  .cm_chart li {
    font-size: 14px;
    position: relative;
    border-top: 2px solid #d0d0d0;
    padding: 15px 0px;
  }

  .cm_count_no {
    position: absolute;
    top: 50%;
    right: 58px;
    font-size: 15px;
    transform: translateY(-50%);
  }

  .select_date .ant-calendar-picker-input.ant-input {
    font-size: 13.5px;
  }

  .cm_count_no span {
    width: -28px;
    margin-left: 10px;
    border-left: 2px solid #d0d0d0;
    position: absolute;
  }

  .cm_chart_info,
  .dashboard_info {
    min-height: 100%;
  }

  label.sub {
    font-size: 1rem;
    font-weight: bold;
  }

  p.cm_year {
    right: 43px;
  }

  .cm_date_select .ant-select-selection-selected-value {
    top: -2px;
  }

  .cm_date_select .ant-select-arrow {
    top: 38%;
  }

  .cm_style {
    position: absolute;
    left: 75px;
    top: 22px;
  }

  .department_img {
    top: 29px;
  }

  .emp_count {
    font-size: 24px;
  }

  .department_btn .btn-primary {
    width: 74px;
  }

  .anticon svg {
    display: inline-block;
  }

  button.ant-modal-close {
    outline: none;
    right: 17px !important;
    top: -10px !important;
  }

  .department_btn .btn-danger {
    width: 74px;
    margin-right: 3px;
    margin-left: -13px;
  }

  span.cm_clear1 {
    width: 12px;
    right: 202px;
  }

  .select_date .ant-calendar-picker {
    left: 29px !important;
  }

  .search_device_bar {
    padding-right: 17px;
    padding-left: 17px;
  }

  .select_date.attend_date .ant-calendar-picker {
    left: 78px !important;
  }

  span.cm_clear {
    right: 32px;
  }

  .ant-calendar-picker-input {
    cursor: pointer;
    width: 114px;
  }

  .search_bar_btn2 {
    width: 75px;
  }

  span.cm_clear4 {
    right: 170px;
  }

  .search_device_bar3 {
    padding-right: 156px;
    padding-left: 50px;
  }

  .search_device_bar4 {
    padding-right: 156px;
    /* padding-left: 50px; */
    margin-bottom: 55px;
  }

  .search_device_bar1 {
    padding-right: 199px;
    padding-left: 15px;
  }

  .search_bar_btn1 {
    width: 75px;
  }

  .search_bar_btn2 {
    right: 162px;
    font-weight: bold;
    background: #2d6bce;
    color: #fff;
    position: absolute;
    border-radius: 7px;
    top: 0;
    width: 75px;
    height: 40px;
  }

  .search_device_bar2 {
    padding-right: 161px;
    padding-left: 61px;
  }

  .search_device_bar2 .select_date .ant-calendar-picker {
    max-width: 118px;
    left: -62px !important;
  }

  .search_device_bar2 span.cm_clear1 {
    position: absolute;
    width: 12px;
    right: 173px;
  }

  .ant-select-arrow .ant-select-arrow-icon {
    display: block;
    position: absolute;
    left: -11px;
  }

  .nav-tabs a {
    /* width: 8rem; */
  }

  .cm_card {
    padding: 16px;
  }
}

@media (max-width: 1073px) {
  .cm_card p {
    font-size: 14px;
    margin-bottom: 0px;
  }
}

@media (max-width: 761px) {
  .cm_card p {
    font-size: 18px;
    margin-bottom: 0px;
  }

  .leave-button {
    flex-direction: column-reverse;
    justify-content: end;
  }

  .leave-button button {
    margin-bottom: 20px;
  }

  .profile_user span {
    border-radius: 11px;
    display: inline-block;
    padding-right: 45px;
  }

  .cm_drop {
    position: absolute;
    right: -18px;
    top: 10px;
  }
}

.ant-fullcalendar-disabled-cell .ant-fullcalendar-value {
  color: rgba(0, 0, 0, 0.25);
  border-radius: 100%;
  cursor: not-allowed;
  font-weight: bold;
  width: 35px;
}

@media (min-width: 1400px) {
  .cm_container {
    max-width: 85% !important;
  }
}

@media (max-width: 1500px) {
  .ant-fullcalendar-disabled-cell .ant-fullcalendar-value {
    width: 35px !important;
  }

  .cm_atten_date.ant-select.ant-select-enabled {
    right: 12px;
  }
}

@media (max-width: 1500px) {
  .el-oc-card {
    padding-left: 0 !important;
    padding-right: 0 !important;
    padding-top: 40px !important;
  }
}

@media (max-width: 1400px) {
  .dashboard_chart > div {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .cm_remain {
    width: 84px;
    font-size: 16px;
    word-break: break-all;
    margin-bottom: 0;
  }

  .select_attendance_date .ant-calendar-picker {
    left: 167px;
  }

  .cm_card.dashboard_card p {
    font-size: 19px;
    margin-top: 55px;
  }

  h3.total-Device {
    font-size: 21px;
    margin-top: 55px;
  }

  .cm_chart_owl {
    margin: 30px auto;
  }

  .cm_card {
    min-height: 108px;
    padding: 15px;
  }

  .cm_card.dashboard_card {
    min-height: 160px;
  }

  .card_time {
    position: static;
  }

  .cm_date_select {
    margin-left: 98px;
  }
}

@media (max-width: 1330px) {
  .body_container .cm_loader .s_cm_loader {
    width: 100%;
  }

  .user_employee_profile {
    grid-gap: 50px;
    gap: 50px;
  }

  .cm_clearNew {
    top: 215px !important;
    right: 42px !important;
  }

  .select_attendance_date .ant-calendar-picker {
    left: 156px;
  }

  /* .cm_remain { */
  /* width: 125px; */
  /* } */

  i.fa.fa-trash-o.delet_proj {
    font-size: 33px;
    position: absolute;
    top: 125px;
  }

  button.btn.btn-primary.btn_csv {
    background: #2d6bce;
    z-index: 99;
    position: absolute;
    right: 21px;
    top: 200px;
  }

  .ant-fullcalendar-disabled-cell .ant-fullcalendar-value {
    width: 35px !important;
  }

  .cm_atten_date.ant-select.ant-select-enabled {
    right: 51px;
  }

  .cm_select_year.ant-select.ant-select-enabled {
    width: 30%;
  }

  p.cm_year {
    margin-right: 12px;
    top: 204px;
  }

  .cm_date_select {
    margin-left: 133px;
  }

  .fa.fa-bell-o {
    left: auto;
    right: 30px;
  }

  .cm_breadcrumb {
    top: 106px;
    /* left: -23px; */
  }

  .ant-modal.sm_modal1 {
    width: 90% !important;
  }

  .image-upload {
    right: -42px;
  }

  .cm_edit .fa-pencil {
    font-size: 20px;
    margin-left: -3px;
  }

  .menu_open .sidebar {
    left: 0px;
  }

  .sidebar {
    left: -330px;
    z-index: 999;
    /* top: 75px; */
    max-height: 100vh;
    transition: all 0.3s;
  }

  .cm_res_sapce {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }

  .ant-modal.sm_modal {
    width: 60% !important;
  }

  .mobile_trigger {
    position: absolute;
    width: 26px;
    height: 30px;
    left: 15px;
    top: 21px;
    z-index: 99;
    cursor: pointer;
  }

  .body_container {
    margin-left: 0;
    padding: 0 15px;
    padding-top: 125px !important;
  }

  header {
    position: fixed;
    width: 100%;

    background: #fff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.16);
    padding: 15px;
  }

  .mobile_trigger span {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 99;
    cursor: pointer;
  }

  .early_coming {
    border-radius: 5px;
    position: absolute;
    top: -21px;
    right: 70px;
    color: #fff;
    background: transparent;
    width: 100%;
    height: 100%;
    border-radius: 11px;
  }
}

.rem_date_select .ant-calendar-picker {
  display: block;
  border: 1px solid #c4c9ce;
  border-radius: 11px;
  width: 149px;
}

.form-login input {
  border-radius: 160px;
  padding-left: 45px;
  font-weight: 600;
  min-height: 48px;
}

input.ant-time-picker-input {
  border: 0;
  position: relative;
  top: 8px;
}

form input.error {
  border-color: red;
}

.cm_timepick .error {
  border-color: red;
}

.input_error .cm_timepick {
  border-color: red;
}

.form-login input {
  border-radius: 160px;
  padding-left: 45px;
  font-weight: 600;
  min-height: 48px;
}

form input.error {
  border-color: red;
}

.form-login .form-control.error {
  border-color: red;
}

.PhoneInputCountry {
  display: none !important;
}

input.PhoneInputInput {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  height: 49px;
  font-size: 16px;
  transition: 0.3s;
  background: #fff;
  border-radius: 11px;
}

@media (max-width: 1199px) {
  img.login {
    display: none;
  }

  .select_attendance_date .ant-calendar-picker {
    left: 167px;
  }

  .cm_chart_owl {
    margin: 30px auto;
  }

  .dashboard_info {
    min-height: 1px;
  }

  .cm_chart_owl {
    width: 177px;
  }

  .cm_chart_owl_val {
    left: 50%;
  }

  .nav-tabs {
    margin-top: -20px;
  }

  .nav-tabs a {
    margin-bottom: 38px !important;
  }

  .image-upload {
    margin-bottom: 60px;
  }

  .ant-modal.attend_modal {
    width: 50% !important;
  }
}

@media (min-width: 1331px) {
  .cm_close,
  .mobile_trigger {
    display: none !important;
  }

  /* .select_attendance_date .ant-calendar-picker {

    left: 112px;
  
  } */
}

@media (max-width: 1023px) {
  .bg_white {
    padding: 40px 15px;
    min-height: 500px;
    margin: 0px 10px 0px 10px;
  }

  .select_attendance_date .ant-calendar-picker {
    left: 167px;
  }

  .form-login {
    max-width: 90%;
  }

  .cm_breadcrumb {
    top: 88px;
    left: 15px;
  }

  .cm_breadcrumb ul {
    padding: 0;
  }

  .ant-modal.sm_modal,
  .ant-modal.attend_modal {
    width: 75% !important;
  }
}

@media (max-width: 767px) {
  .dashboard-detail {
    display: grid;
    grid-template-columns: 1fr;
  }

  .search_device_bar2 .select_date .ant-calendar-picker {
    left: 15px;
  }

  .search_device_bar2 {
    /* padding-left: 140px !important; */
  }

  .ant-modal.sm_modal,
  .ant-modal.attend_modal {
    width: calc(100% - 30px) !important;
  }

  .select_date .ant-calendar-picker {
    left: 15px;
  }
}

.cm_overflow {
  max-height: 375px;
  overflow: auto;
}

span.error_msg.cm_pos_bot {
  bottom: 0;
}

.selectHeight .ant-select-selection--multiple {
  border-radius: 10px;
}

.removeCursor {
  cursor: auto !important;
}

.txt_adjust {
  margin-top: 91px;
  margin-left: 562px;
}

.cm_three_dots {
  display: inline-block;
  width: 100%;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

.cm_rem_dot {
  display: inline-block;
  width: 99px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

.ant-select-selection-selected-value {
  left: 4px;
  position: absolute;
  opacity: 1;
  display: contents;
}

.pos {
  margin: 0px;
}

span.form-control.cm_hide_upload {
  position: relative;
}

span.form-control.cm_hide_upload input {
  position: absolute;
  left: 0;
  top: 0;
  font-size: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
}

.loader_wrapper_2 {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.6);
  z-index: 999;
}

.loader_wrapper_2 .spinner_border {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.padding {
  padding-top: 95px;
  padding-left: 535px;
}

.res_error_msg {
  padding-left: 21%;
  /* align-content: center; */
  color: red;
  bottom: -22px;
  font-size: larger;
}

.cm_post .ant-select-selection.ant-select-selection--single {
  background: #7460ee;
}

.no-data {
  margin-left: -9%;
  margin-top: 3%;
}

.users_details p {
  word-break: break-all;
}

.yellow_btn1 {
  color: #fff;
  width: 126px;
  font-weight: bold;
  font-size: 14px;
  border: none;
  border-radius: 9px;
  height: 39px;
  margin-left: 32px;
  background: #f6b12b;
}

.blue_btn1 {
  color: #fff;
  width: 126px;
  font-weight: bold;
  font-size: 14px;
  border: none;
  border-radius: 9px;
  height: 39px;
  background: #4ec6da;
}

.vertical-container {
  display: table;
  width: 100%;
  height: 100%;
}

.vertical-body {
  display: table-cell;
  vertical-align: middle;
  width: 100%;
}

.title {
  color: #009df1;
}

.pagenot {
  background: linear-gradient(-45deg, #ffffff, #ffffff, #8e9eab, #eef2f3);
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
}

.cm_404 {
  color: #009df1;
  font-weight: bold;
  font-size: 7rem;
  margin-bottom: 0;
  text-shadow: 10px 10px 5px rgba(0, 0, 0, 0.5);
}

.notFound {
  font-size: 2rem;
  color: #000 !important;
  text-shadow: 5px 5px 5px rgba(0, 0, 0, 0.5);
}

.btnPage404 {
  background: linear-gradient(#347bde, #347bde);
  padding: 0 12px;
  border: none;
  border-radius: 20px;
  box-shadow: 10px 10px 5px rgba(0, 0, 0, 0.5);
  outline: none;
  color: #fff;
  font: 400 16px/2.5 Nunito, "Varela Round", Sans-serif;
  text-transform: uppercase;
  cursor: pointer;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

.mini_loader_dept.leave_loader {
  left: 181px;
  bottom: -32px;
}

.select-admin-reimbursement {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding: 0rem 0rem 0.5rem 0rem;
}

.select-admin-reimbursement-action {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 1rem 2rem;
}

#searchByYearFilter {
  width: 15%;
}

#searchByMonthFilter {
  width: 15%;
  margin: 0rem 1rem;
}

@media screen and (max-width: 716px) {
  #searchByYearFilter {
    width: 30%;
  }

  #searchByMonthFilter {
    width: 30%;
  }
}

.vendorCard {
  display: flex;
  flex-direction: row;
}

.vendorImage {
  width: 7rem;
  height: 7rem;
  border-radius: 0.5rem;
  margin-right: 1rem;
}

.vendorCardsDiv {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.vendor-card-details-div {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.headerDiv {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.vendorOrder-filterDiv {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.vendorOrder-filters {
  width: 30%;
  margin: 0rem 2rem 0rem 1rem;
}

@media screen and (max-width: 820px) {
  .headerDiv {
    flex-direction: column;
  }

  .vendorOrder-filters {
    width: 30%;
    margin: 0rem 2rem 0rem 0rem;
  }
}

@media screen and (max-width: 598px) {
  .vendorOrder-filterDiv {
    flex-direction: column;
  }

  .vendorOrder-filters {
    width: 100%;
    margin: 0rem 0rem 1rem 0rem;
  }
}

#pendingStatusColor {
  color: orange;
}

#acceptedStatusColor {
  color: tomato;
}

#completedStatusColor {
  color: #00d915;
}

#rejectedStatusColor {
  color: red;
}

#cancelledStatusColor {
  color: grey;
}

.claimreimbursementButton {
  width: 150px;
  background-color: #fff;
  border-width: 1px;
  border-radius: 8px;
}

.claimreimbursementCancelLink {
  color: #000;
  border-width: 2px;
  border-color: #000;
}

.sectionsDiv {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.interviewModalInputs {
  margin: 0rem 0.3rem;
}

.addCandidateButtonsDiv {
  display: flex;
  width: 100%;
  justify-content: space-around;
  align-items: center;
}

.buttonWidth {
  width: 150px;
}

.errorTextDiv {
  width: 100%;
  margin: 0rem 0rem 1rem 0rem;
  display: flex;
  font-size: larger;
  justify-content: center;
  align-items: center;
}

.errorText {
  color: red;
  font: bold;
}

.assigned-list {
  margin-top: 60px;
}

.customer-content-ul {
  /* display: flex; */
  overflow-x: auto;
  /* padding: 20px 0px; */
}

.piture {
  height: 10px;
}

.customer-content-ul {
  height: 200px;
  grid-gap: 10px;
  gap: 10px;
}

/* .linkbtn1 {
  position: absolute;
  background-color: red;
  height: 45px;
  width: 45px;
  z-index: 1;
  border: 1px solid white;
  left: -2px;
}

.linkbtn2 {
  top: 25%;
  position: absolute;
  background-color: red;
  height: 45px;
  width: 45px;
  z-index: 1;
  border: 1px solid white;
  right: -5px;
} */

.customer-content-ul-box {
  position: relative;
  width: 95%;
  margin: 0 auto;
}

.arrow-btn-left,
.arrow-btn-right {
  position: absolute;
  z-index: 10;
  top: 40%;
  transform: translateY(-50%);
  cursor: pointer;
}

.arrow-btn-left {
  left: -30px;
}

.arrow-btn-right {
  right: -30px;
}

.slick-arrow {
  background-color: transparent !important;
  top: 30% !important;
}

.slick-arrow::before,
.slick-arrow::after {
  font-size: 32px !important;
  /* background-color: dodgerblue !important; */
  color: dodgerblue !important;
}

.slick-prev {
  transform: translate(-5px, -50%) !important;
}

.slick-next {
  transform: translate(-5px, -50%) !important;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.input_department {
  height: 25px;
  width: 25px;
}

.department-checkbox {
  height: 23px;
  width: 23px;
}

.leave-detail {
  position: absolute;
  width: 15rem;
  /* height: 14rem; */
  top: 0;
  left: 100%;
  /* transform: translateX(120%); */
  z-index: 10;
  border: 1px solid dodgerblue;
  border-radius: 20px;
}

.marked-leave-detail {
  position: absolute;
  width: 15rem;
  /* height: 14rem; */
  top: 0;
  right: 10%;
  z-index: 10;
  border: 1px solid dodgerblue;
  border-radius: 20px;
}

.compOff-admin {
  position: absolute;
  right: 4px;
  top: 4px;
}

.compOff-admin-detail {
  position: absolute;
  width: 15rem;
  /* height: 14rem; */
  top: 20px;
  right: 10%;
  z-index: 10;
  border: 1px solid dodgerblue;
  border-radius: 20px;
}

.award-info-detail {
  position: absolute;
  width: 20rem;
  /* height: 14rem; */
  top: 0;
  left: 110%;
  z-index: 10;
  border: 1px solid dodgerblue;
  border-radius: 20px;
}

.re-claim-button {
  position: relative;
}

.reimbursement-leave-detail {
  position: absolute;
  width: 15rem;
  /* height: 14rem; */
  text-align: left;
  top: 0%;
  right: 25%;
  /* bottom: %; */
  z-index: 10;
  border: 1px solid dodgerblue;
  border-radius: 20px;
}

.arrow-left {
  width: 0;
  height: 0;
  border-top: 15px solid transparent;
  border-bottom: 15px solid transparent;

  border-right: 15px solid dodgerblue;
  display: inline-block;
  position: absolute;
  top: 25px;
  left: -15px;
}

.marked-arrow-left {
  width: 0;
  height: 0;
  border-top: 15px solid transparent;
  border-bottom: 15px solid transparent;

  border-left: 15px solid dodgerblue;
  display: inline-block;
  position: absolute;
  top: 25px;
  left: 239px;
}

.reimbursement-arrow-left {
  width: 0;
  height: 0;
  border-top: 15px solid transparent;
  border-bottom: 15px solid transparent;

  border-left: 15px solid dodgerblue;
  display: inline-block;
  position: absolute;
  top: 18px;
  left: 239px;
}

.card-content {
  color: grey;
  font-style: italic;
  font-size: 12px;
}

/* .marked-card_padd { */
/* height: 235px; */
/* } */

.card-row {
  flex-wrap: nowrap;
}

/* .yearly-short-leave { */
/* border-bottom: 2px solid gray; */
/* width: 400px; */
/* } */

@media (max-width: 767px) {
  .attendence-tab {
    display: flex;
    flex-wrap: wrap;
    grid-gap: 80px;
    gap: 80px;
  }

  #leave_tab_button {
    margin: 0 !important;
    left: 0 !important;
  }
}

@media (max-width: 530px) {
  .attendance_tab .nav-tabs a {
    width: 100%;
    margin-top: 0 !important;
    margin-right: 0px !important;
  }

  .holiday_tab.nav-tabs a {
    border-color: #347bde !important;
    color: #347bde !important;
    background: #fff !important;
    margin-bottom: 30px !important;
    margin-right: 10px !important;
    margin-left: 3px;
    border-radius: 8px !important;
    width: 100%;
  }
}

/* .transcctionDate{
  width: 300px !important;
  background-color: red;
} */
/* .container{
  position: relative;
  min-height: 100vh;
  background-color: #ddd;
}
.container .image-container{
  display: flex;
  flex-wrap: wrap;
  gap:15px;
  justify-content: center;
  padding: 10px;
}
.container .image-container .image{
  height:250px;
  width:350px;
  border:10px solid #fff;
  box-shadow: 0 5px 15px rgba(0, 0, 0, .1);
  overflow-x: hidden;
  cursor: pointer;
}
.container .image-container .image img{
  height: 100%;
  width: 100%;
  object-fit: cover;
  transition: .2s linear;
}
.container .image-container .image:hover img{
  transform: scale(1.1);
}
.container .popup-image{
  position: fixed;
  top:0;left: 0;
  background-color: rgba(0, 0, 0, 0.9);
  height: 100%;
  width: 100%;
  z-index: 100;
  display: none;
}
.container .popup-image img{
  position: absolute;
  top: 50%;left: 50%;
  transform: translate(-50%,-50%);
  border: 5px solid #fff;
  border-radius: 5px;
  width: 750px;
  object-fit: cover;
}
@media(max-width:768px){
  .container .popup-image img{
    width:95px
  }
} */

@media screen and (max-width: 1520px) {
  .reject-button {
    margin-left: 0 !important;
    margin-top: 2px;
  }
}

@media screen and (max-width: 2120px) {
  .leave-reject-button {
    margin-left: 0 !important;
    margin-top: 2px;
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
span {
  padding: 0px;
  margin: 0;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.title {
  font-size: 45px;
  text-align: center;
  font-weight: 900;
  text-decoration: underline;
}

.title-image {
  height: 120px;
}

.custom_container {
  max-width: 90%;
  margin: auto;
}

.Reimbursement_head {
  text-align: center;
  font-size: 35px;
  font-weight: bold;
  margin-bottom: 25px;
  text-decoration: underline;
}

.Reimbursement_detail {
  font-size: 20px;
  font-weight: 600;
  line-height: 37px;
}

.device_btn {
  position: absolute;
  transform: translate(0px, 100px);
  z-index: 9;
  cursor: pointer;
  background-color: #347bde;
  border-color: #347bde;
  right: 0;
  color: white;
  width: 10rem;
  max-width: 10rem;
  padding: 10px 20px;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  border-radius: 5px;
}

table tr td {
  /* padding: 10px 5px; */
  /* font-size: 20px; */
}

table > thead > tr > td {
  font-size: 22px;
  font-weight: bold;
}

.Table-bottom-detail {
  font-size: 20px;
  font-weight: bold;
}

.table_note {
  font-size: 25px;
  font-weight: 700;
  line-height: 28px;
}

.reimbursement-form-detail {
  font-size: 18px;
}

.last_content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.last_content_left {
  font-size: 22px;
  font-weight: 800;
}

@media print {
  /* Hide every other element */
  body * {
    visibility: hidden;
  }

  /* Then displaying print container elements */
  .re-from-print-container,
  .re-from-print-container * {
    visibility: visible;
  }

  /* Adjusting the position to always start from top left */
  .re-from-print-container {
    position: absolute;
    left: 0;
    top: 0;
  }
}

.reimbusement-view {
  width: 1300px !important;
  /* height: 900px !important; */
}

.cm_overflow_reimbursement {
  height: 690px;
  overflow: auto;
}

.table-width {
  width: 12rem;
}

.leave-reason p {
  font-size: 14px !important;
}

@media screen and (max-width: 761px) {
  .add_team_div {
    flex-direction: column;
  }

  .add_team_div div {
    max-width: 100%;
  }
}

.div_form_label input {
  width: 100% !important;
  border-radius: 10px;
  padding-left: 10px;
  padding-right: 10px;

  margin: 15px 0px;
}

.div_form_label label {
  margin-top: 10px;
}

.wrapper-class {
  padding: 1rem;
  border: 1px solid #ccc;
  border-radius: 10px;
  margin-right: 15px;
}

.editor-class {
  padding: 1rem;
}

.department-modal {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.cc-field {
  display: none;
}

@media (max-width: 780px) {
  .department-modal {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 520px) {
  .department-modal {
    display: grid;
    grid-template-columns: 1fr;
  }

  .pagination {
    justify-content: center;
  }

  .filter-date {
    width: 100%;
  }

  .select_year_cal {
    width: 100%;
    bottom: 0px;
    border-radius: 10px;
  }

  .select_year_cal.rang_pick.mr-3 {
    width: 100%;
    height: 45px !important;
    border: 1px solid #c4c9ce;
  }

  .rang .ant-calendar-picker-input {
    width: 100%;
  }

  .btn {
    width: 100%;
  }

  .profile_user.dropdown-toggle.btn.btn-primary {
    width: auto;
  }

  .approve_leave {
    padding: 0px;
  }

  .cm_ticket_btn .btn {
    font-size: 15px;
    border-color: #347bde !important;
    box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);
    color: #2d6bce !important;
    background: #fff !important;
    margin-bottom: -60px !important;
    margin-right: 9px !important;
    border-radius: 8px !important;
    width: 100%;
    text-align: center;
  }

  .nav-tabs a {
    width: 100%;
  }
}

@media screen and (max-width: 991px) {
  .cc-field {
    display: block;
    max-width: 90%;
  }
  .margin_right {
    margin-right: 1rem;
  }
  .project-information {
    display: flex;
    flex-wrap: wrap;
  }

  .project-summary {
    width: 100%;
  }
}

.image-size {
  width: 9%;
  height: 45px;
}

.image-size svg {
  height: 100%;
}

.upload_doc_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #edfbff;
  border-radius: 8px;
  padding: 10px;
  height: 3.5rem;
  margin: 5px;
}

.mdClose {
  color: #347bde;
  cursor: pointer;
  width: 24px;
  height: 24px;
}

.doc-upload {
  border-radius: 8px;
  border-width: 1px;
  padding: 10px;
  overflow: auto;
  min-height: 200px;
  max-height: 500px;
}

.custom-modal {
  width: 80%;
}

.template_pdf{
  height: 700px !important;
}
.view_ticket_reason {
  width: 4rem;
  border-radius: 4px;
  border: none;
  height: 2rem;
}

.table_width1 {
    width: 1rem;
}

.table_width6{
    width: 6rem;
}
.cover-spin1 {
    /* content:'';
    display:block; */
    position: absolute;
    left: 50%;
    bottom: 35%;
    width: 80px;
    height: 80px;
    border-style: solid;
    border-color: #347bde;
    border-top-color: transparent;
    border-width: 7px;
    border-radius: 50%;
    animation: spin .8s linear infinite;
}

.admin_attendance {
    opacity: 0.5;
}

.input-excel {
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
}
.employeeDetails-container {
  width: 100%;
  padding-top: 6%;
}
.headersDiv {
  display: flex;
  justify-content:flex-start;
  flex-direction: row;
}
.contentDiv {
  margin-top: 1.4%;
}
.marginTop {
  margin-top: 2rem;
}
.employeeDetailsDiv {
  margin-left: 3rem;
}
.userInfo{
  font-family: 'Nunito', sans-serif;
  font-weight: 500;
}
@media screen and (max-width: 430px) {
  .headersDiv {
    flex-direction: column-reverse;
  }
  .employeeDetailsDiv {
    margin: 1rem 0rem 1rem 0rem;
  }
}
@media screen and (max-width: 1330px) {
  .employeeDetails-container {
    padding-top: 1%;
  }
}
.actionDiv{
  width:100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.tableDescDiv{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}
/* ----------modal css ------------- */

.modalButtonsDiv{
  display: flex;
  justify-content: flex-end;
}
.loaderDiv{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Nunito', sans-serif;
}

.display{
  justify-content: flex-end!important;
}
.table_name{
  color:blue;
}
.emp_dashboard_card {
  padding: 2rem;
  margin: 2rem;
  background-color: #fff;
  border-radius: 1rem;
  overflow-y: scroll;
  height: 22rem;
  width: 20rem;
  overflow: hidden;
}

.emp_dashboard_card_header {
  grid-gap: 1rem;
  gap: 1rem;
  margin-bottom: 1.5rem;
}

.emp_dashboard_card_title {
  font-size: 17px;
  font-weight: 600;
  color: #5c546a;
}

.emp_dashboard_card_text {
  font-weight: 300;
  font-size: 14px;
  line-height: 19px;
}

.card-separator {
  background-color: #a9a9a9;
  height: 0.1rem;
  display: flex;
}

.card-link {
  color: #5360e1;
  font-weight: 400;
}

.dashboard_container {
  padding-top: 94px !important;
  background-image: linear-gradient(
    to bottom right,
    rgb(169 183 230),
    rgb(159 159 159 / 0%),
    rgba(255, 0, 0, 0)
  );
}

.dashboard_icon {
  height: 19rem;
  width: 19rem;
  margin-left: 2rem;
  position: absolute;
  right: 10px;
  top: 56px;
}

@media (min-width: 1012px) and (max-width: 1330px) {
  .dashboard_icon {
    top: 75px;
    right: -9px;
  }
}

@media (max-width: 1012px) {
  .dashboard_icon {
    display: none;
  }
}

@media (max-width: 388px) {
  .emp_dashboard_card {
    width: 15rem !important;
  }
  .dashboard_new_card {
    min-width: 13em !important;
    max-width: 13rem !important;
  }
}

.dashboard_leave_input {
  height: 30px;
  position: relative;
  border-radius: 3px;
  border-color: #d4d1f2;
  border-width: 1px;
}

.icon_clear {
  position: absolute;
  right: 3px;
  top: -30px;
}

.leave_main {
  display: grid;
  margin-top: 24px;
  grid-gap: 8px;
  gap: 8px;
}

.dashboard_leave_btn {
  width: 6rem;
  background-color: #5c5f7f;
  border: none;
  height: 34px;
  border-radius: 3px;
  margin-top: 24px;
  color: white;
}

.dashboard_new_card_container {
  background-color: #fff;
  border-radius: 16px;
  padding-bottom: 1rem;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.dashboard_CardItem {
  font-size: 0.8rem;
  margin-left: 0.5rem;
  font-weight: 100;
}

.dashboard_new_card {
  display: flex;
  align-items: center;
  border-radius: 4px;
  border: 1px solid #d3d3d3;
  min-width: 18rem;
  max-width: 18rem;
  padding: 8px;
}

.card_body {
  grid-gap: 16px;
  gap: 16px;
  max-height: 300px;
  overflow: scroll;
}

.card_icon_img {
  height: 2rem;
}
.card_icon_gift_img {
  height: 1.5rem;
}

.dashboard_designation {
  color: #716e7b;
  font-size: 10px;
  line-height: 16px;
}

.dashboard_birthdate {
  color: #716e7b;
  margin-left: 7px;
  font-size: 10px;
  line-height: 16px;
  background-color: #dddddf;
  display: flex;
  justify-content: center;
  width: 5rem;
  border-radius: 1px;
  margin-top: 3px;
}

.dashboard_update {
  padding: 14px;
}

.dashboard_update_p {
  font-size: 24px;
}

.dashboard_update_link {
  color: #716e7b
}

.dashboard_new_heading {
  display: flex;
  flex-direction: column;
  grid-gap: 4px;
  gap: 4px;

}
.wrapper {
  background-color: white;
  border-radius: 0.3rem;
}

.email-input {
  width: 100%;
  height: 45px;
  padding: 0 1rem;
  box-sizing: border-box;

  font-family: circe, sans-serif;
  border-radius: 10px;
  border: 1px solid #d4d5d6;
  color: #565656;
  -webkit-appearance: none;
}

.email-input-height {
  height: 3.2rem !important;
}

.email-input:focus {
  border-color: #347bde;
  outline: none;
}

.email-input.input_error {
  border-color: red;
}


.tag-item {
  font-family: circe, sans-serif;
  color: white;
  background-color: #347bde;
  display: inline-block;
  font-size: 14px;
  border-radius: 30px;
  height: 30px;
  padding: 0 4px 0 1rem;
  display: inline-flex;
  align-items: center;
  margin: 0 0.3rem 0.3rem 0;
}


.tag-item>.button {
  background-color: white;
  color: black;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  font: inherit;
  margin-left: 10px;
  font-weight: bold;
  padding: 0;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.upload-modal {
  width: 30%;
}

.delete-compoff-btn{
  height: 2.4rem;
  margin-right: 1rem;
}

@media (max-width: 520px) {
  .delete-compoff-btn {
    font-size: 18px;
    margin-left: 1rem;
    margin-bottom: 2rem;
    margin-top: -2.5rem;
  }
}

.temp-compoff-details {
  font-size: 17px;
  line-height: 22px;
}
.file-container {
  border: 2px solid;
  border-color: #c5e3fc;
  border-radius: 7px;
  padding: 1rem;
}

.document-container {
  max-height: 250px;
  overflow-y: auto;
}

.upload_mini_loader_dept {
  width: 40px;
  height: 40px;
  border: 2px #347bde solid;
  border-radius: 50%;
  animation: rotation 1s ease-in-out infinite;
  margin: 30px auto;
}

.upload_mini_loader_dept:after {
  width: 8px;
  height: 8px;
  background-color: rgb(0, 0, 0);
  border-radius: 100%;
  position: absolute;
  content: "";
}
.image-size {
    width: 9%;
    height: 45px;
}

.image-size svg {
    height: 100%;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.cover-spin {
    /* content:'';
    display:block; */
    position: absolute;
    left: 50%;
    bottom: 15%;
    width: 80px;
    height: 80px;
    border-style: solid;
    border-color: #347bde;
    border-top-color: transparent;
    border-width: 7px;
    border-radius: 50%;
    animation: spin .8s linear infinite;
}

.claim_reimbursement {
    opacity: 0.5;
}
.lds-ellipsis {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-ellipsis div {
    position: absolute;
    top: 33px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: #347bde;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  .lds-ellipsis div:nth-child(1) {
    left: 8px;
    animation: lds-ellipsis1 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(2) {
    left: 8px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(3) {
    left: 32px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(4) {
    left: 56px;
    animation: lds-ellipsis3 0.6s infinite;
  }
  @keyframes lds-ellipsis1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes lds-ellipsis3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
  @keyframes lds-ellipsis2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(24px, 0);
    }
  }
  
.canvaspdf {
    height: 936px !important;
}
.paginationDiv {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

#exportButton {
  background-color: #347bde;
  color: white;
  width: 8rem;
  border: none;
  padding: .5rem;
  display: flex;
  justify-content: center;
  border-radius: .5rem;
  font-size: medium;
}
@font-face {
    font-family: 'TrashHand';
    src: url(/static/media/TrashHand.5bac457e.eot);
    src: url(/static/media/TrashHand.5bac457e.eot) format('embedded-opentype'),
        url(/static/media/TrashHand.ce8eae41.woff2) format('woff2'),
        url(/static/media/TrashHand.f91c6613.woff) format('woff'),
        url(/static/media/TrashHand.ddfb91ef.ttf) format('truetype'),
        url(/static/media/TrashHand.e7d85fb0.svg) format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
}

:root {
    --color-text-brown: #512D17;
    --color-text-white: #fff;
}


body {
    box-sizing: border-box;
}

.card-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.card-box {
    max-width: 600px;
    position: relative;
}

.card-bg {
    width: 100%;
    height: 100%;
}

.person-img-box {
    position: relative;
    text-align: center;

}

.person-img {
    transform: translateY(7.5%);
    width: 100%;
    -webkit-clip-path: circle(50% at 50% 50%);
            clip-path: circle(50% at 50% 50%);
}

.center-content {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: grid;
    grid-template-columns: 1fr 5.12fr 1fr;
}

.ribbon-box {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 10;
}

.ribbon {
    width: 100%;
    height: 100%;
}

.round-shadow {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    transform: translateY(7.5%);
}

.cake {
    position: absolute;
    top: 67%;
    left: 17%;
    width: 80.2%;
    /* transform: translateY(-100px) translateX(50px); */
}

.person-name-box {
    width: 100%;
    text-align: center;
    font-size: 75px;
    position: absolute;
    top: 119%;
    left: 0;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    line-height: 85px;
    font-family: 'TrashHand';
    color: var(--color-text-brown);
}



@media only screen and (max-width: 500px) {
    .person-name-box {
        font-size: 60px;
        top: 116%;
    }
}

@media only screen and (max-width: 440px) {
    .person-name-box {
        font-size: 45px;
        top: 115%;
    }
}

@media only screen and (max-width: 370px) {
    .person-name-box {
        font-size: 45px;
        top: 113%;
    }
}


.template-container {
    /* display: grid; */
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 20px;
    column-gap: 20px;
}

.birthday-dropdown {
    align-self: center;
}

.birthday-dropdown__btn {
    border: none;
    font-size: 14px;
    background-color: #347bde;
    color: #fff;
    padding: 5px 10px;
    border-radius: 5px;
    font-weight: 500;
    /* border: 3px solid #2962b3; */

}


.back_btn {
    position: -webkit-sticky;
    position: sticky;
    top: 100px;
    align-self: flex-start;
}
.person-name-box1 .person-name{
    font-size: 48px;
    line-height: 60px;
    font-weight: 800;
    font-style: italic;
    margin-top: 30px;
    text-transform: capitalize;
    display: block;
}
.person-name-box2 .person-name{
    font-size: 13px;
    line-height: 20px;
        font-weight: normal;
    color: #000;
    display: block;
    margin: 10px auto;
}
.error{
    color: red;
}
.person-name-box1 .person-name2{
    font-size: 40px;
    line-height: 50px;
    margin: 16px 0px 0px;    
}
.person-name-desc{
    font-size: 13px;
    line-height: 18px;
    font-weight: 400;
}
.person-name-desg{
    font-size: 18px;
    line-height: 30px;
}
.person-name-box11 .person-name{
    font-size: 35px;
    line-height: 40px;
    font-weight: 800;
    font-style: italic;
    margin-top: 30px;
    text-transform: capitalize;
    display: block;
}


.paginationDiv{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
#exportButton{
  background-color: #347bde;
  color: white;
  width: 8rem;
  border: none;
  padding: .5rem;
  display: flex;
  justify-content: center;
  border-radius: .5rem;
  font-size: medium;
}
.privacy_card {
  margin: 2rem;
  background-color: white;
  border-radius: 1rem;
  border: 1px solid #d3d3d3;
  width: 20rem;
  height: 15.5rem;
}

.privacy_img {
  width: 14rem;
  height: 14rem;
}

.privacy_ellipsis {
  display: flex;
  justify-content: end;
}

.privacy_card_container {
  grid-gap: 4rem;
  gap: 4rem;
}

.privacy_img_container {
  border-radius: 1rem 1rem 0px 0px;
  height: 81%;
}

.privacy_img_container:hover {
  cursor: pointer;
}

.privacy_view_doc {
  width: 1rem;
  height: 1rem;
}

.privacy_view_doc_btn {
  position: absolute;
  right: 2px;
  bottom: 6px;
  border: none;
  background: #f2f2f2;
}

.policy_dropdown {
  top: 26px !important;
  right: 10px !important;
}

.privacy_card_title {
  display: flex;
  position: absolute;
  text-align: center;
  align-items: center;
  justify-content: center;
  bottom: -49px;
  background: rgb(169 183 230);
  font-family: Arial, sans-serif;
  width: 100%;
  min-height: 22%;
  border-radius: 0px 0px 1rem 1rem;
}

.canvaspdf {
  height: 700px;
}

.view_pdf {
  height: 500px;
  overflow: scroll;
  width: 100%;
}

.pdf_img {
  height: 200px;
}

.privacy_dropdown {
  position: absolute;
  right: 4px;
  top: 4px;
  z-index: 1;
}

.privacy_dropdown_menu .dropdown-menu {
  left: auto !important;
  right: 11px !important;
  transform: none !important;
  top: 40px !important;
  border: none;
  border-radius: 6px;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1) !important;
  z-index: 999;
  padding: 10px 0px;
  background: #eaeaea !important;
}

.privacy_dropdown_menu .dropdown-item {
  display: block;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

.dropdown-toggle {
  background: none;
  border: none;
}

.dropdown-toggle span {
  color: black;
}

.dropdown-toggle:hover,
.show > .btn-primary.dropdown-toggle {
  background-color: #eeeeee;
}

.policy_card_loader {
  top: 23%;
  position: absolute;
  right: 44%;
}

.dot-elastic {
  top: 50%;
  left: 50%;
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #9880ff;
  color: #9880ff;
  animation: dot-elastic 1s infinite linear;
}
.dot-elastic::before,
.dot-elastic::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
}
.dot-elastic::before {
  left: -15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #9880ff;
  color: #9880ff;
  animation: dot-elastic-before 1s infinite linear;
}
.dot-elastic::after {
  left: 15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #9880ff;
  color: #9880ff;
  animation: dot-elastic-after 1s infinite linear;
}

@keyframes dot-elastic-before {
  0% {
    transform: scale(1, 1);
  }
  25% {
    transform: scale(1, 1.5);
  }
  50% {
    transform: scale(1, 0.67);
  }
  75% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1, 1);
  }
}
@keyframes dot-elastic {
  0% {
    transform: scale(1, 1);
  }
  25% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1, 1.5);
  }
  75% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1, 1);
  }
}
@keyframes dot-elastic-after {
  0% {
    transform: scale(1, 1);
  }
  25% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1, 0.67);
  }
  75% {
    transform: scale(1, 1.5);
  }
  100% {
    transform: scale(1, 1);
  }
}

canvas {
  height: 248px;
  width: 100%;
  padding: 1.5rem;
}

._1CDf-Fj0nLQTDw84Sho5JS {
  height: 200px !important;
  width: 100% !important;
  border-radius: 1rem 1rem 0px 0px;
}

/*  */
.investment-btn {
  font: 15px;
}
/*  */
.dob-font {
  font-weight: 100;
}
.text {
  text-decoration: underline;
}
h6 {
  font-size: 13px;
}
table tr td th thead {
  background-color: white !important;
}

.set-width {
  width: 5% !important;
}
.width {
  width: 25%;
}
.position {
  align-items: center;
  justify-content: space-between;
  padding-bottom: 50px;
}
hr {
  border: 1px solid grey;
}

.button-style {
  background-color: #117dedfa;
  padding: 9px;
  border-radius: 6px;
  color: white;
  font-weight: bold;
}

.text-size {
  font-size: 17px;
}
.input {
  border: none;
  width: 70%;
}
.input1 {
  width: 30%;
}
.error {
  color: red;
  float: right;
}
.input-box {
  border-bottom: 1px solid black;
}
.headings {
  display: flex;
}
.space {
  justify-content: space-between;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.table_name:hover {
  cursor: pointer;
}

@media print {
  body * {
    visibility: hidden;
  }
  .print-container,
  .print-container * {
    visibility: visible;
  }
  .print-container {
    position: absolute;
    left: 0;
    top: 0;
  }
}

.upload_investment_doc {
  position: relative !important;
  top: 0;
  right: 0 !important;
}

.search_icon {
  right: 12px;
  top: 14px;
}
  
.upload_container {
  border: 1px solid #b9bec1;
  border-radius: 10px;
  padding: 1rem;
  background: #e0f2f7;
}

.upload_view_btn {
  width: 60px;
}
.select_year_cal.rang_pick {
  width: 234px;
  height: 50px !important;
  border: 1px solid #c4c9ce;
}

span.rang.select_year_cal.rang_pick.ant-calendar-picker {
  margin-top: 0 !important;
}

span.rang.select_year_cal.rang_pick.ant-calendar-picker:focus {
  box-shadow: none !important;
}

.ant-calendar-picker-input.ant-input {
  margin-top: 2px;
}
.ant-table {
  overflow-x: scroll;
}
.ant-table-thead > tr > th{
background-color: #347bde;
color: white;
font-weight: 500;
}
.teamchart_main_div {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}

.attendance_team_chart {
    border-right: solid black;
}

.leave_team_chart {
    border-left: solid black;
}


.chartjs-render-monitor {
    width: 800px;
    height: 500px;
    max-width: 100%;
}

.team_search_bar {
    padding-left: 183px;
}

.team_search_bar .form-control {
    height: 54px;
    padding-right: 30px;
}

span.team_clear {
    position: absolute;
    width: 12px;
    right: 30px;
    top: 18px;
    cursor: pointer;
}

@media screen and (max-width: 820px) {
    .teamchart_main_div {
        grid-template-columns: 1fr;
    }

    .team_search_bar {
        padding: 0px;
    }


    .attendance_team_chart {
        border-bottom: solid black;
        border-right: none;
        margin-bottom: 5px;
        padding-bottom: 30px;
    }

    .leave_team_chart {
        border-left: none;
    }

    .project_team_chart {
        border-bottom: solid black;
        border-right: none;
        margin-bottom: 5px;
        padding-bottom: 30px;
    }
}

.highcharts-credits {
    display: none;
}
.nested_table{
    display: flex;
    padding: 0px;
}

.nested_table td{
    width: 50%;
    text-align: center;
}
@media screen and (max-width: 768px) {
    .team_add_button {
        margin-top: 5px;
    }
}
.salary-generation-button {
    /* margin-left: 10px; */
    display: flex;
    flex-wrap: wrap;
    grid-gap: 10px;
    gap: 10px;
}

.bg-red {
    background-color: #ff8080;
}

.salary-status-hold .ant-select-selection.ant-select-selection--single {
    background: #dc3545;
    color: #fff;
    height: 37px;
    border: none;
    border-radius: 7px;
}

.salary-status-clear .ant-select-selection.ant-select-selection--single {
    background: #65cca1;
    color: #fff;
    height: 37px;
    border: none;
    border-radius: 7px;
}
.otp_background {
    background-color: #696969;
    display: flex;
    align-items: center;
    justify-content: center;
}

.opt_contanier {
    border-radius: 15px;
    background-color: white;
    padding: 20px;
}

.opt_contanier h2 {
    color: #8f9294;
    font-weight: 600;
}

.get_otp_container {
    width: 500px;
    height: 150px;
    justify-content: center;
}


.get_otp_input_container {
    width: 80px !important;
    height: 80px;
    font-size: 30px;
    color: #8f9294;

    border: none;
    border-bottom: 2px solid #c4c9ce;
    margin-right: 20px;
}

@media screen and (max-width: 720px) {
    .get_otp_container {
        width: 400px;
        height: 150px;
        justify-content: center;
    }
    .get_otp_input_container {
        width: 50px !important;
        height: 50px;
        font-size: 20px;
        border: none;
        border-bottom: 2px solid #c4c9ce;
        margin-right:15px;
    }

}
@media screen and (max-width: 460px) {
    .get_otp_container {
        width: 300px;
        height: 150px;
        justify-content: center;
    }
    .get_otp_input_container {
        width: 40px !important;
        height: 40px;
        font-size: 10px;
        /* border-radius: 10px; */
        border: none;
        border-bottom: 2px solid #c4c9ce;
        margin-right:15px;
    }

}

.get_otp_input_container:focus {
    outline: none;
}

.get_otp {
    text-align: end;
    color: blue;
    max-width: 700px;
    cursor: pointer;
    font-size: 20px;
}

.validate_button {
    padding: 15px 80px 15px 80px;
    font-size: 30px;
    border-radius: 15px;
}
.year_award {
    overflow: auto;
    scroll-snap-type: x;
}

.awarded_empolyee_image_container {
    position: relative;
    height: 130px;
    width: 130px;
}

.awarded_empolyee_image {
    margin: auto;
    height: 120px;
    width: 120px;
    border-radius: 59px;
    border: 2px solid black;
    margin-bottom: 20px;
    overflow: hidden;
}

.yearly_award_content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.awarded_empolyee_image img {
    width: 100%;
    cursor: pointer;
    height: 100%;
}

.award_main_div {
    display: flex;
    justify-content: center;
    align-items: center;
    grid-gap: 100px;
    gap: 100px;
    margin-bottom: 20px;
}

.award_descriptonIcon {
    position: absolute;
    top: 5px;
    right: 0px;
    cursor: pointer;
}

.yearly_award_content h3 {
    text-wrap: nowrap;
    font-size: 18px;
}

.giventoname {
    font-size: 18px;
}
.organization-btn {
  background-color: #404e7c;
  color: #fef9ef;
  text-align: center;
  font-size: 1.5em;
  z-index: 1;
  border: none;
  width: 7rem;
  height: 3rem;
  margin-bottom: 1rem;
  text-decoration: none;
  border-radius: 8px;
}

.orgChart {
  height: calc(100vh - 60px);
  border: 1px solid #e4e2e9;
  border-radius: 5px;
}

.empDetailCard {
  position: absolute;
  top: 60px;
  width: 45%;
  padding: 2rem;
  margin: 2rem;
  background-color: #f2f2f2;
  border-radius: 1rem;
  border: 1px solid #d3d3d3;
  overflow-y: scroll;
}

.cardCloseBtn {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 30px;
  height: 30px;
  color: #227c9d;
  background-color: #fef9ef;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #227c9d;
  cursor: pointer;
}

.cardHeader {
  text-align: center;
  margin-bottom: 1rem;
}

.cardImg {
  width: 120px;
  border-radius: 1rem;
  height: 120px;
}
.cardName {
  margin-top: 1rem;
  font-size: 1rem;
  font-weight: bold;
}
.cardRole {
  margin: 1rem 0;
  font-size: 1.2rem;
}
.cardBody {
  display: flex;
  flex-direction: column;
}
.cardBodyTeamMembers {
  margin-top: 1rem;
  height: 26vh;
  overflow-y: auto;
}
.cardItem {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.cardItemLabel {
  margin: 0.5rem 0;
  font-size: 16px;
  font-weight: bold;
  line-height: 15px;
}
.cardItemValue {
  text-align: justify;
  font-size: 16px;
  font-weight: 500;
}
.cardItemTeam {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.cardItemImg {
  width: 50px;
  height: 50px;
  margin: 0.2rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cardItemText {
  font-size: 30px;
  color: white;
}

.cardItemName {
  margin-left: 0.5rem;
  font-weight: 500;
  font-size: 18px;
  line-height: 16px;
}
.cardItemTeamInfo {
  font-size: 0.8rem;
  margin-left: 0.5rem;
  font-weight: 100;
}

.icon {
  margin-right: 0.5rem;
}

.nodeContainer {
  width: 300px;
  height: 150px;
  padding-top: 25px;
  padding-left: 1px;
  padding-right: 1px;
}

.nodeDetails {
  font-family: "Inter, sans-serif";
  background-color: #fff;
  margin-left: -1px;
  width: 298px;
  height: 127px;
  border-radius: 10px;
  border: 1px solid #e4e2e9;
  box-shadow: 7px 7px #e4e2e9;
}
.nodeId {
  display: flex;
  justify-content: flex-end;
  margin-top: 5px;
  margin-right: 8px;
}

.nodeContent {
  background-color: #fff;
  margin-top: -47px;
  margin-left: 15px;
  border-radius: 100px;
  width: 50px;
  height: 50px;
}

.nodeImgContainer {
  margin-top: -47px;
}

.nodeNoProfile {
  margin-top: -50px;
}

.nodeImg {
  margin-left: 15px;
  border-radius: 100px;
  width: 50px;
  height: 50px;
  margin-top: -3px;
}

.nodeName {
  display: flex;
  font-size: 15px;
  color: #08011e;
  margin-left: 20px;
  margin-top: 10px;
}

.nodeDepartment {
  color: #716e7b;
  margin-left: 20px;
  margin-top: 3px;
  font-size: 10px;
}

.nodeInfoContainer {
  background-color: #f1f1f2;
  margin: 8px;
  line-height: 20px;
  border-radius: 6px;
}

.nodeInfo {
  grid-gap: 4px;
  gap: 4px;
}

.month_year {
  grid-gap: 1rem;
  gap: 1rem;
}

.expandBtn {
  width: 30px;
  height: 30px;
  margin: auto;
  color: #227c9d;
  background-color: #fef9ef;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #d3d3d3;
  border-radius: 50%;
  cursor: pointer;
}

.backgroundOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.cardItemTeamDetails {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 82%;
}

@media print {
  body * {
    visibility: hidden;
  }
  .template_detail * {
    visibility: visible;
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
  }
}

.calendar_container {
  padding: 1rem;
  background: #f1f1f1;
}

.calendar {
  width: 500px;
  border-radius: 8px;
  padding: 16px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background: white;
}

.calendar-header {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 16px;
  font-size: 1.2rem;
  font-weight: bold;
}

.calendar-grid {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-gap: 8px;
  gap: 8px;
}

.calendar-day-name,
.calendar-day {
  text-align: center;
  padding: 8px;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 300;
}

.calendar-day-name {
  font-weight: bold;
}

.calendar-day {
  background-color: #f0f0f0;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.calendar-day.empty {
  background-color: transparent;
}

.halfLeave {
  background-color: #10efda;
}

.present {
  background-color: #92d3c3;
}

.leave {
  background-color: #f2a26b;
}

.absent {
  background-color: #f67a7a;
}

.holiday {
  background-color: #ccb3f6;
}

.no_attendance {
  background-color: #c2c0c0;
}

.calendar-day.prev-next-month {
  color: #c8c8ca;
  background-color: white;
}

.circle {
  display: inline-block;
  width: 10px;
  height: 10px;
  line-height: 30px;
  text-align: center;
  border-radius: 50%;
  color: #fff;
}

.template3_date {
  background: #14a445;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 6px 14px;
  color: white;
  border-radius: 3px 3px 0px 0px;
  position: relative;
  left: 5rem;
  margin-top: 1rem;
}

.template3_data {
  border: 1px solid #14a445;
  min-height: 22rem;
  padding: 2rem 1rem 1rem 1rem;
  width: 100%;
  overflow: auto;
}

.template3_status {
  margin-left: 4px;
  font-size: 16px;
  font-weight: 300;
}

.template3_status_title {
  font-size: 16px;
  font-weight: 600;
}

.template3_table_width {
  min-width: 100px;
}

.template3_table_title_width {
  min-width: 130px;
}

.template3_table_body {
  border-left: 1px solid #c8c8ca;
  border-bottom: 1px solid #c8c8ca;
  border-right: 1px solid #c8c8ca;
  border-top: 2px solid white;
}

.template3_row {
  padding: 6px;
}

.template3_row_data {
  display: flex;
  justify-content: end;
  border: 1px solid;
  margin-left: 6px;
  padding-right: 3px;
}

.template3_col_header {
  background: #e3efef;
  border: 1px solid white;
}

.template3_week_header {
  border: 1px solid white;
}

.template3_total_hours {
  background: #fffede;
  padding-right: 3px;
}

.template3_container {
  padding-bottom: 3rem;
}

.final_total_hours {
  background-color: #ffcd3a;
}

.template3_tasks {
  font-size: 13px;
  font-weight: 400;
}

.calendar_container2 {
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
}

.calendar2 {
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  overflow: hidden;
}

.calendar-header2 {
  text-align: center;
  padding: 16px;
  background-color: #f5f5f5;
  border-bottom: 1px solid #e0e0e0;
}

.calendar-grid2 {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
}

.calendar-day-name2 {
  text-align: center;
  padding: 8px;
  background-color: #f5f5f5;
  border: 1px solid #eeeeee;
  font-size: larger;
  font-weight: 500;
}

.calendar_day {
  height: 70px;
  align-items: start;
  justify-content: space-around;
}

.prev_next_month {
  background-color: #f2f6f7;
}

.time-slots {
  font-size: 12px;
  margin-top: 4px;
}

.status_Absent,
.status_Present,
.status_Leave,
.status_HalfDay,
.status_Holiday {
  font-size: 14px;
  font-weight: bold;
  margin-top: 4px;
}

.status_A {
  background-color: #f67a7a;
}

.status_P {
  background-color: #92d3c3;
}

.status_L {
  background-color: #f2a26b;
}

.status_H-D {
  background-color: #10efda;
}

.status_H {
  background-color: #ccb3f6;
}

.color-description {
  padding: 16px;
  background-color: #f5f5f5;
}

.template2_status_detail {
  font-size: 12px;
  font-weight: 400;
  padding: 2px 4px;
}

.privacy-card-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.days {
  top: 3px;
  right: 8px;
}

.emp_time {
  font-size: 12px;
  font-weight: 300;
  /* position: absolute; */
  left: 12px;
  top: 5px;
  color: #a69b9b;
  margin-left: 8px;
}

.emp_status {
  /* position: absolute; */
  /* bottom: 12%; */
  font-size: 12px;
  font-weight: 400;
  /* left: 11px; */
  padding: 2px 4px;
}

.time_day {
  width: 100%;
  padding: 0px 5px;
}

.statusClass {
    width: 100%;
    margin-left: 26px;
}

