.paginationDiv{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
#exportButton{
  background-color: #347bde;
  color: white;
  width: 8rem;
  border: none;
  padding: .5rem;
  display: flex;
  justify-content: center;
  border-radius: .5rem;
  font-size: medium;
}