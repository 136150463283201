canvas {
  height: 248px;
  width: 100%;
  padding: 1.5rem;
}

._1CDf-Fj0nLQTDw84Sho5JS {
  height: 200px !important;
  width: 100% !important;
  border-radius: 1rem 1rem 0px 0px;
}
