.cover-spin1 {
    /* content:'';
    display:block; */
    position: absolute;
    left: 50%;
    bottom: 35%;
    width: 80px;
    height: 80px;
    border-style: solid;
    border-color: #347bde;
    border-top-color: transparent;
    border-width: 7px;
    border-radius: 50%;
    animation: spin .8s linear infinite;
}

.admin_attendance {
    opacity: 0.5;
}

.input-excel {
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
}