.image-size {
    width: 9%;
    height: 45px;
}

.image-size svg {
    height: 100%;
}

@-webkit-keyframes spin {
    from {
        -webkit-transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.cover-spin {
    /* content:'';
    display:block; */
    position: absolute;
    left: 50%;
    bottom: 15%;
    width: 80px;
    height: 80px;
    border-style: solid;
    border-color: #347bde;
    border-top-color: transparent;
    border-width: 7px;
    border-radius: 50%;
    animation: spin .8s linear infinite;
}

.claim_reimbursement {
    opacity: 0.5;
}