.emp_dashboard_card {
  padding: 2rem;
  margin: 2rem;
  background-color: #fff;
  border-radius: 1rem;
  overflow-y: scroll;
  height: 22rem;
  width: 20rem;
  overflow: hidden;
}

.emp_dashboard_card_header {
  gap: 1rem;
  margin-bottom: 1.5rem;
}

.emp_dashboard_card_title {
  font-size: 17px;
  font-weight: 600;
  color: #5c546a;
}

.emp_dashboard_card_text {
  font-weight: 300;
  font-size: 14px;
  line-height: 19px;
}

.card-separator {
  background-color: #a9a9a9;
  height: 0.1rem;
  display: flex;
}

.card-link {
  color: #5360e1;
  font-weight: 400;
}

.dashboard_container {
  padding-top: 94px !important;
  background-image: linear-gradient(
    to bottom right,
    rgb(169 183 230),
    rgb(159 159 159 / 0%),
    rgba(255, 0, 0, 0)
  );
}

.dashboard_icon {
  height: 19rem;
  width: 19rem;
  margin-left: 2rem;
  position: absolute;
  right: 10px;
  top: 56px;
}

@media (min-width: 1012px) and (max-width: 1330px) {
  .dashboard_icon {
    top: 75px;
    right: -9px;
  }
}

@media (max-width: 1012px) {
  .dashboard_icon {
    display: none;
  }
}

@media (max-width: 388px) {
  .emp_dashboard_card {
    width: 15rem !important;
  }
  .dashboard_new_card {
    min-width: 13em !important;
    max-width: 13rem !important;
  }
}

.dashboard_leave_input {
  height: 30px;
  position: relative;
  border-radius: 3px;
  border-color: #d4d1f2;
  border-width: 1px;
}

.icon_clear {
  position: absolute;
  right: 3px;
  top: -30px;
}

.leave_main {
  display: grid;
  margin-top: 24px;
  gap: 8px;
}

.dashboard_leave_btn {
  width: 6rem;
  background-color: #5c5f7f;
  border: none;
  height: 34px;
  border-radius: 3px;
  margin-top: 24px;
  color: white;
}

.dashboard_new_card_container {
  background-color: #fff;
  border-radius: 16px;
  padding-bottom: 1rem;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.dashboard_CardItem {
  font-size: 0.8rem;
  margin-left: 0.5rem;
  font-weight: 100;
}

.dashboard_new_card {
  display: flex;
  align-items: center;
  border-radius: 4px;
  border: 1px solid #d3d3d3;
  min-width: 18rem;
  max-width: 18rem;
  padding: 8px;
}

.card_body {
  gap: 16px;
  max-height: 300px;
  overflow: scroll;
}

.card_icon_img {
  height: 2rem;
}
.card_icon_gift_img {
  height: 1.5rem;
}

.dashboard_designation {
  color: #716e7b;
  font-size: 10px;
  line-height: 16px;
}

.dashboard_birthdate {
  color: #716e7b;
  margin-left: 7px;
  font-size: 10px;
  line-height: 16px;
  background-color: #dddddf;
  display: flex;
  justify-content: center;
  width: 5rem;
  border-radius: 1px;
  margin-top: 3px;
}

.dashboard_update {
  padding: 14px;
}

.dashboard_update_p {
  font-size: 24px;
}

.dashboard_update_link {
  color: #716e7b
}

.dashboard_new_heading {
  display: flex;
  flex-direction: column;
  gap: 4px;

}